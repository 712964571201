/* eslint-disable react-hooks/rules-of-hooks */
import TagManager from 'react-gtm-module'
import { v4 as uuidv4 } from 'uuid'
import { formatShopifyPrice } from './formatPrice'
import { getIdFromSourceEntryId } from './sourceEntryId'
import { DateTime } from 'luxon'
import sha1 from 'crypto-js/sha1'
import sha256 from 'crypto-js/sha256'

function buildProductData(products, type, url) {
  return products.map((product, index) => {
    const firstVariant = product.variants[0]
    const subscribable =
      product.tags.includes('Subscription') ||
      product.tags.includes('Subscribe') ||
      product.tags.includes('Build')
    const data = {
      id: firstVariant.sku, // SKU
      name: product.content.title, // Product title
      brand: 'Sitka Seafood Market',
      category: subscribable ? 'Subscribable' : 'OTP' || '',
      variant: firstVariant.content.title,
      price: firstVariant.price.toString(),
      quantity: '1',
      product_id: getIdFromSourceEntryId(product.sourceEntryId), // The product_id
      variant_id: getIdFromSourceEntryId(firstVariant.sourceEntryId), // id or variant_id
      compare_at_price: firstVariant?.compareAtPrice?.toString() || '', // If available on dl_view_item & dl_add_to_cart otherwise use an empty string
      image: firstVariant.featuredMedia?.src || '', // If available, otherwise use an empty string
      list: url || '', // TODO: technically we display multple lists on pages, we should use a block name instead of URL
      position: index.toString() || '', // position in the list of search results, collection views and position in cart indexed starting at 1
    }

    return data
  })
}

function buildProductDataFromCheckout(lineItems) {
  return lineItems.map((item, index) => {
    const merchandise = item.merchandise
    const product = item.product || item.variant.product
    const variant = item.variant
    const price = variant.price?.amount
      ? formatShopifyPrice(variant.price.amount)
      : formatShopifyPrice(variant.price)
    const compare_at_price = variant.compareAtPrice?.amount
      ? formatShopifyPrice(variant.compareAtPrice.amount)
      : formatShopifyPrice(variant.compareAtPrice)
    const subscribed = item?.sellingPlanAllocation
    return {
      id: variant.sku, // SKU
      name: product.content?.title || product.title, // Product title
      brand: 'Sitka Seafood Market',
      url:
        'https://sitkaseafoodmarket.com/products/' +
          merchandise?.product?.handle || product?.handle,
      category: subscribed ? 'Subscribed' : 'OTP',
      variant: variant.title,
      price,
      quantity: item.quantity.toString(),
      product_id: getIdFromSourceEntryId(product.sourceEntryId || product.id), // The product_id
      variant_id: getIdFromSourceEntryId(variant.id), // id or variant_id
      compare_at_price, // If available on dl_view_item & dl_add_to_cart otherwise use an empty string
      image: product?.content
        ? product.content?.featuredMedia?.src
        : merchandise?.image.url || '', // If available, otherwise use an empty string\
      position: index,
    }
  })
}

export const dataLayerATC = ({ item }) => {
  const products = buildProductDataFromCheckout([item])

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_cart',
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      ecommerce: {
        add: {
          products,
        },
      },
      eventModel: {
        currency: 'USD',
        value: item.variant.price,
        items: [
          {
            item_id: getIdFromSourceEntryId(item.product.sourceEntryId),
            item_name: item.product.title,
            item_variant: item.variant.title,
            quantity: item.quantity,
            price: item.variant.price,
            currency: 'USD',
            item_category: item?.product?.tags.includes('Subscription Box')
              ? 'Subscription Box'
              : item?.product?.tags.includes('freezer') ||
                  item?.product?.tags.includes('One Time Purchase')
                ? 'One Time Purchase'
                : '',
          },
        ],
      },
    },
  })
}

export const dataLayerRFC = ({ item }) => {
  const products = buildProductDataFromCheckout([item])

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'remove_from_cart',
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      ecommerce: {
        remove: {
          products,
        },
      },
      eventModel: {
        currency: 'USD',
        value: parseFloat(item.variant.price.amount),
        items: [
          {
            item_id: getIdFromSourceEntryId(item.variant.product.id),
            item_name: item.variant.product.title,
            item_variant: item.variant.title,
            quantity: item.quantity,
            price: parseFloat(item.variant.price.amount),
            currency: 'USD',
            item_category: item?.product?.tags.includes('Subscription Box')
              ? 'Subscription Box'
              : item?.product?.tags.includes('freezer') ||
                  item?.product?.tags.includes('One Time Purchase')
                ? 'One Time Purchase'
                : '',
          },
        ],
      },
    },
  })
}

export const dataLayerViewProductList = ({ products, url }) => {
  const viewedProducts = products.filter((p) => !!p.sourceEntryId)
  const legacy_item_list = viewedProducts.map((item) => {
    return {
      item_id: item.sourceEntryId.replace('gid://shopify/Product/', ''),
      item_name: item.content.title,
    }
  })

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item_list',
      // user_properties: user_properties,
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      ecommerce: {
        impressions: buildProductData([...viewedProducts], 'collection', url),
      },
      eventModel: {
        item_list_name: url,
        items: legacy_item_list,
      },
    },
  })
}

export const dataLayerViewSearchResults = ({ search_term, products }) => {
  const uniqueKey = uuidv4()
  const legacy_item_list = products.map((item) => {
    return {
      item_id: item.id.toString(),
      item_name: item.title,
    }
  })
  if (products.length > 0) {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } })
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_search_results',
        // user_properties: user_properties,
        event_id: uniqueKey.toString(), // unique uuid for FB conversion API
        event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
        search_term: search_term,
        ecommerce: {
          actionField: { list: 'search results' },
          impressions: products.map((item, index) => {
            return {
              //TODO: replace with buildProductData
              id: item.sku, // SKU
              name: item.title, // Product title
              brand: 'Sitka Seafood Market',
              category: item?.product?.tags.includes('Subscription Box') // TODO: evaluate if product has selling plans, or was selected with a selling plan
                ? 'Subscription Box'
                : item?.product?.tags.includes('freezer') ||
                    item?.product?.tags.includes('One Time Purchase')
                  ? 'One Time Purchase'
                  : '',
              variant: item.variant_title,
              price: item.price,
              quantity: '1',
              product_id: item.id.toString(), // The product_id
              variant_id: '', // id or variant_id
              compare_at_price: item.compare_at_price
                ? item.compare_at_price
                : '', // If available on dl_view_item & dl_add_to_cart otherwise use an empty string
              image: item.image || '', // If available, otherwise use an empty string
              position: index.toString(),
            }
          }),
        },
        eventModel: {
          item_list_name: 'search results',
          items: legacy_item_list,
        },
      },
    })
  }
}

export const dataLayerSelectProduct = ({ product, url }) => {
  const uniqueKey = uuidv4()

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'select_item',
      // user_properties: user_properties,
      event_id: uniqueKey.toString(), // unique uuid for FB conversion API
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      ecommerce: {
        click: {
          actionField: { list: url, action: 'click' }, // this should be the collection page URL
          products: buildProductData([product]),
        },
      },
    },
  })
}

export const dataLayerViewCart = ({ cart }) => {
  if (!cart?.lineItems?.length) {
    return false
  }
  const lineItems = cart.lineItems.map((item) => {
    return {
      item_id: item.merchandise.sku,
      item_name: item.title,
      ProductHandle: item.merchandise.product.handle,
      ProductURL:
        'https://sitkaseafoodmarket.com/products/' +
        item.merchandise.product.handle,
      ImageURL: item.merchandise.image.url,
      quantity: item.quantity,
      price: item.cost.amountPerQuantity.amount,
      currency: 'USD',
    }
  })
  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_cart',
      // user_properties: user_properties,
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      cart_total: formatShopifyPrice(cart.cost.checkoutChargeAmount.amount),
      ecommerce: {
        actionField: { list: 'Shopping Cart' },
        impressions: buildProductDataFromCheckout(cart.lineItems),
      },
      eventModel: {
        cartID: cart.id.replace('gid://shopify/Cart/', ''),
        cartTotal: cart.cost.totalAmount.amount,
        cartItems: lineItems,
      },
    },
  })
}

export const dataLayerBeginCheckout = ({ cart }) => {
  const legacy_lineItems = cart.lineItems.map((item) => {
    return {
      item_id: item.merchandise.sku,
      item_name: item.title,
      ProductHandle: item.merchandise.product.handle,
      ProductURL:
        'https://sitkaseafoodmarket.com/products/' +
        item.merchandise.product.handle,
      ImageURL: item.merchandise.image.url,
      quantity: item.quantity,
      price: item.cost.amountPerQuantity.amount,
      currency: 'USD',
    }
  })

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_checkout',
      // user_properties: user_properties,
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      cart_total: formatShopifyPrice(cart.cost.totalAmount.amount),
      ecommerce: {
        checkout: {
          actionField: { step: 'Final', action: 'checkout' },
        },
        products: buildProductDataFromCheckout(cart.lineItems),
      },
      eventModel: {
        cartID: cart.id.replace('gid://shopify/Cart/', ''),
        cartTotal: cart.cost.totalAmount.amount,
        cartItems: legacy_lineItems,
      },
    },
  })
}

export const dataLayerCheckoutComplete = ({ order }) => {
  const legacy_lineItems = order.lineItems.map((item) => {
    return {
      item_id: getIdFromSourceEntryId(item.product.id),
      item_name: item.title,
      item_variant: item.variant.title,
      quantity: item.quantity,
      price: parseFloat(item.originalUnitPriceSet.presentmentMoney.amount),
      currency: 'USD',
      item_category: item?.product?.tags.includes('Subscription Box') // TODO: evaluate if product has selling plans, or was selected with a selling plan
        ? 'Subscription Box'
        : item?.product?.tags.includes('freezer') ||
            item?.product?.tags.includes('One Time Purchase')
          ? 'One Time Purchase'
          : '',
    }
  })

  var newUser = true
  if (order.customer?.id) {
    const customerCreatedAt = new Date(order.customer.createdAt)
    var today = new Date()
    if (customerCreatedAt <= today.setHours(0, 0, 0, 0)) {
      newUser = false
    }
  }

  var discount = ''
  var discount_amt = 0
  if (order.discountCodes.length > 0) {
    discount = order.discountCodes.join(', ')
    discount_amt = parseFloat(order.totalDiscountsSet.presentmentMoney.amount)
  }

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  //TODO: replace with ecomerce datalayer and buildCheckoutProductData
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      // user_properties: user_properties,
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      eventModel: {
        transaction_id: getIdFromSourceEntryId(order.id),
        customer_id: getIdFromSourceEntryId(order.customer?.id),
        user_email_sha256: '' + sha256(order.email),
        user_email_sha1: '' + sha1(order.email),
        is_new_customer: newUser ? 'true' : 'false',
        currency: 'USD',
        value: parseFloat(order.totalPriceSet.presentmentMoney.amount),
        orderPromoCode: discount,
        orderDiscount: discount_amt,
        items: legacy_lineItems,
      },
    },
  })
}

export const dataLayerSignup = ({ customer, url }) => {
  // console.log('customer', customer)
  TagManager.dataLayer({
    dataLayer: {
      event: 'sign_up',
      page: {
        url: url,
      },
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
    },
  })
}

export const dataLayerLogin = ({ customer, url }) => {
  var membership = 'guest'
  if (customer.tags.includes('Employee')) {
    membership = 'Employee'
  } else if (customer.tags.includes('KingSustainer')) {
    membership = 'KingSustainer'
  } else if (customer.tags.includes('SockeyeSustainer')) {
    membership = 'SockeyeSustainer'
  } else if (customer.tags.includes('Prepaid')) {
    membership = 'PrepaidMember'
  } else if (customer.tags.includes('PremiumMember')) {
    membership = 'PremiumMember'
  } else if (customer.tags.includes('Member')) {
    membership = 'Member'
  }
  TagManager.dataLayer({
    dataLayer: {
      event: 'login',
      page: {
        url: url,
      },
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      user_properties: {
        customer_id: getIdFromSourceEntryId(customer.id),
        user_email: customer.email,
        user_email_sha256: '' + sha256(customer.email),
        user_email_sha1: '' + sha1(customer.email),
        membership_tier: membership,
      },
    },
  })
}

export const dataLayerViewProduct = ({ product }) => {
  const uniqueKey = uuidv4()

  TagManager.dataLayer({ dataLayer: { ecommerce: null } })
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item',
      // user_properties: user_properties,
      event_id: uniqueKey.toString(), // unique uuid for FB conversion API
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      ecommerce: {
        detail: {
          products: buildProductData([product]),
        },
      },
      eventModel: {
        currency: 'USD',
        value: product.variants[0].price,
        items: [
          {
            item_id: product.sourceEntryId.replace(
              'gid://shopify/Product/',
              '',
            ),
            item_name: product.content.title,
            item_category: product.tags.includes('Subscription Box') // TODO: evaluate if product has selling plans, or was selected with a selling plan
              ? 'Subscription Box'
              : product.tags.includes('freezer') ||
                  product.tags.includes('One Time Purchase')
                ? 'One Time Purchase'
                : '',
          },
        ],
      },
    },
  })
}

export const dataLayerRouteChange = ({ url }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'route_change',
      url: url,
    },
  })
}

export const dataLayerEvent = ({ event }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
    },
  })
}

export const dataLayerSubEvent = ({ event, subscription }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      eventModel: {
        subscriptionID: subscription?.subscription_id,
      },
    },
  })
}

export const dataLayerViewRecipe = ({ recipe, products, related_content }) => {
  var promoted_products = []
  if (typeof products !== 'undefined') {
    products.forEach((product) => {
      var promoted_product = {}

      promoted_product.title = product.content.title
      promoted_product.image = product.content.featuredMedia.src
      promoted_product.productType = product.productType
      promoted_product.url =
        'https://sitkaseafoodmarket.com/products/' + product.content.handle
      promoted_product.desc = product.content.description

      promoted_products.push(promoted_product)
    })
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'view_recipe',
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      eventModel: {
        recipe: {
          recipe: recipe.shareTitle,
          image: recipe.shareGraphic.asset.url,
          desc: recipe.shareDesc,
          promoted_products: promoted_products,
        },
      },
    },
  })
}

export const dataLayerExperiment = ({ experiment }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'experience_impression',
      event_time: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'), // Timestamp for the event
      eventModel: {
        exp_variant_string: experiment,
      },
    },
  })
}
