export const transformEdges = (object, field) => {
  if (field == null) {
    return object.edges.map((edge) => {
      return edge.node
    })
  } else {
    return object.edges.map((edge) => {
      return edge.node[field]
    })
  }
}

export const GET_PRODUCTS = `query products($filter: ProductFilterInput) {
  allProducts(filter: $filter){
    edges{
      node{
        sourceEntryId
        sourceId
        availableForSale
        productType
        tags
        metafields {
          id
          key
          namespace
          value
        }
        content {
          sourceEntryId
          sourceId
          handle
          title
          description
          metafields {
            namespace
            value
            key
          }
          options {
            name
            values
          }
          featuredMedia {
            altText
            id
            src
            thumbnailSrc
            type
          }
          media {
            id
            src
            thumbnailSrc
            altText
          }
        }
        variants {
          sourceEntryId
          sku
          availableForSale
          price
          compareAtPrice
          weight
          productHandle
          quantityAvailable
          metafields {
            namespace
            value
            key
          }
          content {
            title
            variantEntryId
            selectedOptions {
              name
              value
            }
            featuredMedia {
              src
              thumbnailSrc
              altText
            }
          }
        }
      }
    }
  }
}`;

export const GET_RECENT_ARTICLES = `query articles($first: Int! = 500, $type: String!) {
  allContent(filter: { first: $first, type: $type }) {
    edges{
      node{
        handle
        title
        createdAt
      }
    }
  }
}`
