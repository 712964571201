import productReviewsData from '@/utils/reviews.preval'
import { useMemo } from 'react'

// TODO: why do we have so many different ways of fetching review info? We should consolidate these into a single function

const useProductsReviews = (productHandles = [], limit) => {
  const validHandles = productHandles.filter((handle) => !!handle)

  const mappedReviews = useMemo(() => {
    if (!validHandles.length) {
      return []
    }

    let reviews = []

    validHandles.forEach((productHandle) => {
      const productReviews = productReviewsData.filter(
        (review) => review.productHandle === productHandle,
      )
      reviews = [...reviews, ...productReviews]
    })

    if (limit) {
      return reviews.slice(0, limit)
    }

    return reviews
  }, [productHandles])

  return mappedReviews
}

export default useProductsReviews
