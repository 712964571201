import React from 'react'
import classes from './ResponsiveComponent.module.scss'

const ResponsiveComponent = ({
  desktopBreakpoint,
  component = 'div',
  className = '',
  mobileClassName='',
  desktopClassName='',
  display = 'block',
  desktop,
  mobile,
}) => {
  return (
    <>
      {React.createElement(
        component,
        {
          className: `${classes[desktopBreakpoint]} ${classes[`desktop-${display}`]} ${desktopClassName} ${className}`,
        },
        desktop,
      )}
      {React.createElement(
        component,
        {
          className: `${classes[desktopBreakpoint]} ${classes[`mobile-${display}`]} ${mobileClassName} ${className}`,
        },
        mobile,
      )}
    </>
  )
}

export default ResponsiveComponent
