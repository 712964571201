import { useEffect } from 'react'
import {
  useInfiniteHits,
  useInstantSearch,
} from 'react-instantsearch-hooks-web'
import ModalProductCard from '@/components/Cards/ModalProductCard'
import Link from 'next/link'
import classes from './ModalProductHits.module.scss'
import { dataLayerViewSearchResults } from '@/utils/dataLayer'

const ModalProductHits = (props) => {
  const { hits, results } = useInfiniteHits(props)
  const { scopedResults } = useInstantSearch(props)
  const { indexId } = props
  const foundScoped = scopedResults?.find((index) => index.indexId === indexId)

  useEffect(() => {
    dataLayerViewSearchResults({
      search_term: decodeURI(foundScoped?.results?.query),
      products: hits,
    })
  }, [hits])

  return (
    <div className={classes['hits-wrap']}>
      {results.query !== '' && (
        <div className={classes['hits']}>
          <div className={classes['hits-header']}>
            <h6>
              {foundScoped?.results?.nbHits} product results for{' '}
              <span>&#34;{results.query}&#34;</span>
            </h6>
            <Link href={`/pages/search/?query=${results.query}`} legacyBehavior>
              <a className="secondary--body">View All</a>
            </Link>
          </div>
          <div className={classes['hits-items']}>
            {hits.slice(0, 2).map((hit) => {
              return (
                <div className={classes['grid-item']} key={hit.objectID}>
                  <ModalProductCard product={hit} />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalProductHits
