import { getSanityClient } from '@/services/sanityClient'
import { CONTENT_SECTIONS_GROQ } from '@/utils/contentSectionUtils'

export const getProductContentByHandle = async (
  handle,
  client = getSanityClient(),
) => {
  const QUERY = (handle) => `
    *[handle.current == "${handle}" && _type == "product"][0]
    {
      ...,
      "objectID": _id,
      content[]{
        ${CONTENT_SECTIONS_GROQ},
        productInfoImages[]{
          ...,
          asset->{...}
        },
      },
      productImages[]{
        alt,
        productImage{
          ...,
          asset->{...}
        },
      },
    }
  `

  if (!handle) return undefined
  const product = await client.fetch(QUERY(handle))
  if (!product) return undefined
  return {
    createdAt: product._createdAt,
    fields: {
      __typename: product._type,
      ...product,
    },
    handle: product.handle.current,
    published: true,
    sourceId: product.objectID,
    title: product.title,
    type: product._type,
    updatedAt: product._updatedAt,
  }
}
