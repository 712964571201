import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getAll, get, set } from 'es-cookie'
import sanityClient from '@/services/sanityClient'
import { captureException } from '@sentry/nextjs'
import { dataLayerExperiment } from '@/utils/dataLayer'

export const MarketingContext = createContext()
export function useMarketingContext() {
  return useContext(MarketingContext)
}

export function MarketingProvider({ children }) {
  const router = useRouter()
  const [GAClientId, setGAClientId] = useState(null)
  const [utm_source, setUTMSource] = useState(null)
  const [utm_medium, setUTMMedium] = useState(null)
  const [utm_campaign, setUTMCampaign] = useState(null)
  const [utm_content, setUTMContent] = useState(null)
  const [experiments, setExperiments] = useState(null)
  const [experimentCookies, setExperimentsCookies] = useState(null)
  const [activeExperiments, setActiveExperiments] = useState([])

  useEffect(() => {
    if (GAClientId) sessionStorage.setItem('GAClientId', GAClientId)
    if (utm_source) sessionStorage.setItem('utm_source', utm_source)
    if (utm_medium) sessionStorage.setItem('utm_medium', utm_medium)
    if (utm_campaign) sessionStorage.setItem('utm_campaign', utm_campaign)
    if (utm_content) sessionStorage.setItem('utm_content', utm_content)
  }, [utm_source, utm_medium, utm_campaign, utm_content, GAClientId])

  // MARKETING UTM PARAMS, LATER STORED IN THE CART FOR ATTRIBUTION ANALYTICS
  // TODO: STOP USING COOKIES, move this info to the context and call on it from checkout context directly
  useEffect(() => {
    if (navigator.cookieEnabled && window.self === window.top) {
      if (typeof router.query.utm_source !== 'undefined') {
        setUTMSource(router.query.utm_source)
        console.debug('utm_source: ' + sessionStorage.getItem('utm_source'))
      }
      if (typeof router.query.utm_medium !== 'undefined') {
        setUTMMedium(router.query.utm_medium)
        console.debug('utm_medium: ' + sessionStorage.getItem('utm_medium'))
      }
      if (typeof router.query.utm_campaign !== 'undefined') {
        setUTMCampaign(router.query.utm_campaign)
        console.debug('utm_campaign: ' + sessionStorage.getItem('utm_campaign'))
      }
      if (typeof router.query.utm_content !== 'undefined') {
        setUTMContent(router.query.utm_content)
        console.debug('utm_content: ' + sessionStorage.getItem('utm_content'))
      }

      if (sessionStorage.getItem('referrer') === null) {
        sessionStorage.setItem('referrer', window.frames.top.document.referrer)
        sessionStorage.setItem('landing_page', router.asPath)
        console.debug('set referrer: ' + sessionStorage.getItem('referrer'))
      }

      // TRACK Impact Affiliate referral ID
      if (typeof router.query.irclickid !== 'undefined') {
        sessionStorage.setItem('irclickid', router.query.irclickid)
        console.debug(
          'Impact irclickid: ' + sessionStorage.getItem('irclickid'),
        )
      }
    }
  }, [router.isReady])

  useEffect(() => {
    if (utm_source) sessionStorage.setItem('utm_source', utm_source)
    if (utm_medium) sessionStorage.setItem('utm_medium', utm_medium)
    if (utm_campaign) sessionStorage.setItem('utm_campaign', utm_campaign)
    if (utm_content) sessionStorage.setItem('utm_content', utm_content)
  }, [utm_source, utm_medium, utm_campaign, utm_content])

  useEffect(() => {
    let clientID = []
    clientID = Object.entries(getAll())
      .map((a) => a.join('='))
      .filter(function (c) {
        return c.trim().indexOf('_ga') === 0
      })
      .map(function (c) {
        return c.trim()
      })
    if (clientID.length > 0) {
      setGAClientId(clientID[0].substring(10))
    }
  }, [])

  // LOAD EXPERIMENT DATA FROM SANITY AND COOKIES
  useEffect(() => {
    loadExperiments()
    async function loadExperiments() {
      const QUERY = `
          *[_type == 'experiments' && enabled]
          { title, enabled, control->{ _type, 'handle': handle.current }, tests->{ _type, 'handle': handle.current } }
          [control._type == tests._type] 
          `
      setExperiments(await sanityClient.fetch(QUERY))

      //TODO: make browser safe if no support for cookies?
      try {
        setExperimentsCookies(
          Object.entries(getAll())
            .map((a) => a.join('='))
            .filter(function (c) {
              return c.trim().indexOf('sitkaExp-') === 0
            })
            .map(function (c) {
              return c.trim()
            }),
        )
      } catch (err) {
        console.error('Error retrieving experiment cookies', err.message)
        captureException('Error retrieving experiment cookies', err.message)
      }
    }
  }, [router.asPath])

  useEffect(() => {
    activateExperiment()
  }, [experimentCookies, experiments])

  // SETTINGS BASED EXPERIMENTS LOAD THROUGH THE MARKETING CONTEXT INSTEAD OF THE MIDDLEWARE
  // EACH SETTINGS BASED EXPERIMENT PAGE TYPE NEEDS TO BE QUERIED
  // THE EXPERIMENT COOKIE USES THE PAGE TYPE INSTEAD OF THE PAGE HANDLE AS AN IDENTIFER
  // THIS IS STILL PRETTY HARD CODED .. PROCEED WITH CAUTION
  if (experiments) {
    experiments
      .filter(
        (exp) =>
          exp.control._type === 'navigation' ||
          exp.control._type === 'pricingSettings' ||
          exp.control._type === 'subscriptionOptions',
      )
      .map((exp) => {
        let settingsExperiment = {
          experimentName: exp.title,
          control: exp.control.handle,
          pageType: exp.control._type,
          test: exp.tests.handle,
        }
        // console.log('settings experiment', settingsExperiment)
        const experimentName = settingsExperiment.experimentName
        const expSettingsTrigger =
          settingsExperiment.control || settingsExperiment.pageType

        const THRESHOLD = 0.5
        const COOKIE_NAME =
          'sitkaExp-' +
          expSettingsTrigger +
          '000' +
          experimentName.replace(/\s/g, '')
        const testVariant =
          Math.random() < THRESHOLD ? 'serveControl' : 'serveVariant'

        // set the variant in the cookie if not already set
        //TODO: make browser safe if no support for cookies?
        if (!get(COOKIE_NAME)) {
          set(COOKIE_NAME, testVariant, { expires: 7, path: '/' })
          activateExperiment({
            experimentCookies: [COOKIE_NAME + '=' + testVariant],
          })
        }
      })
  }

  async function activateExperiment() {
    // console.log('exp eval these cookies', experimentCookies)
    // console.log('exp sanity experiments', experiments)

    if (experiments && experimentCookies) {
      const currentHandle =
        router.asPath.split('?')[0] === '/'
          ? 'homepage'
          : router.asPath
              .split('?')[0]
              .substring(
                router.asPath.lastIndexOf('/') + 1,
                router.asPath.length,
              )

      // console.log('valid experiments from Sanity', experiments)
      // console.log('experiments cookies', experimentCookies)
      // console.log('experiments currentHandle', currentHandle)

      //TODO: extend "settings" experiments beyond just "navigation"
      experimentCookies
        .filter(
          (cookie) =>
            cookie.includes(currentHandle) ||
            cookie.includes('pricingSettings') ||
            cookie.includes('subscriptionOptions') ||
            cookie.includes('navigation'),
        )
        .map((cookie) => {
          let experiment = {
            name: cookie.substring(
              cookie.lastIndexOf('000') + 3,
              cookie.lastIndexOf('='),
            ),
            trigger: cookie.substring(9, cookie.lastIndexOf('000')),
            variant: cookie.substring(cookie.lastIndexOf('=') + 1),
            gaString: cookie
              .substring(cookie.lastIndexOf('000') + 3)
              .replace('=', '-'),
          }

          const conductableExperiment = experiments.some(
            (conductableExperiment) =>
              conductableExperiment.title
                .replace(/\s/g, '')
                .includes(experiment.name),
          )

          const activeExperiment = activeExperiments.some((activeExperiment) =>
            activeExperiment.trigger.includes(experiment.trigger),
          )

          //if it is an experiment - push to active list (if not already there), trigger event
          if (
            conductableExperiment &&
            !activeExperiment &&
            (experiments.some((conductableExperiment) =>
              conductableExperiment.control?.handle?.includes(
                experiment.trigger,
              ),
            ) ||
              experiment.trigger === 'navigation' ||
              experiment.trigger === 'pricingSettings' ||
              experiment.trigger === 'subscriptionOptions')
          ) {
            console.debug('experience_impression', experiment)
            dataLayerExperiment({ experiment: experiment.gaString })
            setActiveExperiments((activeExperiments) => [
              ...activeExperiments,
              experiment,
            ])
          }
          // else, remove the cookie (won't be used if the experiment is not active, cookie expires in 7 days)
          // maybe leave cookie there in case the experiment is temporarily paused, that way users resume their prior state
        })
    }
  }

  return (
    <MarketingContext.Provider
      value={{
        setUTMSource,
        setUTMMedium,
        setUTMCampaign,
        setUTMContent,
        activeExperiments,
      }}
    >
      {children}
    </MarketingContext.Provider>
  )
}
