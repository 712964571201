/*
Source Entry Id = gid://shopify/Order/5272726175930
Source Entry Id with Key = gid://shopify/Order/5272726175930?key=1ad0b0201b69bd6b619b9eb8cb09b986
Regular Shopify Id = 5272726175930
*/

export function getIdFromSourceEntryId(sourceEntryId) {
  // Use a regular expression to match and capture the id
  const match = sourceEntryId.match(/\/(\d+)(\?|$)/)

  if (match && match[1]) {
    // The id is captured in the first group (match[1])
    // Use parseInt to convert it to a number
    return parseInt(match[1], 10).toString()
  } else {
    return sourceEntryId;
  }
}

export function buildSourceEntryId({id, objectType}) {
  return `gid://shopify/${objectType}/${id}`
}