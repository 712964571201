import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

const DynamicModal = dynamic(() => import('@/components/Layout/Modal'));

export const ModalContext = createContext()

export function useModalContext() {
  return useContext(ModalContext)
}

export function ModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalProps, setModalProps] = useState(undefined)
  const [content, setContent] = useState('')
  const [prevContent, setPrevContent] = useState('')
  const [modalType, setModalType] = useState(null)
  const router = useRouter()

  useEffect(() => {
    if (isOpen) document.querySelector('html').classList.add('disable-scroll')
    if (!isOpen) document.querySelector('html').classList.remove('disable-scroll')
  }, [isOpen, content])

  useEffect(() => {
    const onRouteChangeComplete = () => {
      setIsOpen(false)
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete)
  }, [router.pathname])

  useEffect(() => {
    if (!isOpen) {
      if (router.query.login === 'true') {
        setIsOpen(true);
        setModalType('login');
      } else if (router.query.register === 'true') {
        setIsOpen(true);
        setModalType('create')
      } else if (router.query.forgotPassword === 'true') {
        setIsOpen(true);
        setModalType('forgot_password')
      }
    }
  }, [router.query])

  return (
    <ModalContext.Provider value={{
      prevContent,
      setPrevContent,
      isOpen,
      setIsOpen,
      modalProps,
      setModalProps,
      content,
      setContent,
      modalType,
      setModalType
    }}>
      {isOpen &&
        <DynamicModal>
          {content}
        </DynamicModal>
      }
      {children}
    </ModalContext.Provider>
  )
}