import { BreadcrumbJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

const Breadcrumb = () => {
  const router = useRouter()

  let urlPathArray = router.asPath.split('/').slice(1)

  let itemListElements = urlPathArray.map((item, index) => {
    const path = urlPathArray
      .filter((_, itemIndex) => itemIndex <= index)
      .join('/')
    const url = `${process.env.NEXT_PUBLIC_DOMAIN}/${path}`
    return {
      position: index + 1,
      name: item
        .split('-')
        .map((s) => s.charAt(0)?.toUpperCase() + s.slice(1))
        .join(' '),
      item: url,
    }
  })

  itemListElements = itemListElements
    .filter((el) => el.name !== '')
    .map((item, index) => {
      return {
        ...item,
        // TODO: could add the name of the element here
        position: index + 1,
      }
    })

  return itemListElements.length > 0 ? (
    <BreadcrumbJsonLd itemListElements={itemListElements} />
  ) : undefined
}

export default Breadcrumb
