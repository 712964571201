export const CONTENT_GATING_HIERARCHY = [
  'experimentalproductaccess',
  'employee',
  'kingsustainer',
  'sockeyesustainer',
  'prepaid',
  'member'
]

function getFormattedTag(tag) {
  return tag?.replace(/-/g, '').replace(/ /g, '').toLowerCase()
}

export const userCanAccessContent = ({ contentTags, userTags }) => {
  if (!contentTags) return true
  const visibleTags = getVisibleTags(contentTags)
  if (visibleTags.length < 1) {
    // content has no gating tags, user can view content
    return true
  }
  if ((!userTags || userTags.length < 0) && visibleTags && visibleTags.length > 0) {
    // user has no tags, but tags required to view content
    return false
  }
  return visibleTags.some(vt => userTags.some(ut => {
    const userTag = getFormattedTag(ut)
    return CONTENT_GATING_HIERARCHY.indexOf(userTag) <= CONTENT_GATING_HIERARCHY.indexOf(vt) && CONTENT_GATING_HIERARCHY.indexOf(userTag) >= 0
  })
  )
}

export const getVisibleTags = (tags) => {
  return tags.reduce((array, tag) => {
    if (tag.toLowerCase().includes('visible')) {
      const splitTag = tag.split(':')[1]?.trim()
      if (splitTag) array.push(getFormattedTag(splitTag))
    }
    return array
  }, [])
}

export const getContentGatingModal = ({ modals, contentTags }) => {
  if (!contentTags) return undefined
  const visibleTags = getVisibleTags(contentTags)
  if (visibleTags.length < 0) return undefined
  return modals.reduce((carry, modal) => {
    const carryHandle = getFormattedTag(carry?.handle)
    const modalHandle = getFormattedTag(modal.handle)
    if (visibleTags.some(tag => tag.indexOf(modalHandle) > -1)) {
      if (!carryHandle) return modal
      if (CONTENT_GATING_HIERARCHY.indexOf(modalHandle) > CONTENT_GATING_HIERARCHY.indexOf(carryHandle)) {
        return modal
      }
    }
    return carry
  }, undefined)
}
