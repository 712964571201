import { createContext, useCallback, useContext, useState, useMemo } from 'react'
import { useCustomerContext } from './CustomerContext'
import { getAvailableAddons, getAvailableSwaps } from '@/utils/getProducts'
import { userCanAccessContent } from '@/utils/gatingUtils'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { getIdFromSourceEntryId } from '@/utils/sourceEntryId'
import { captureException } from '@sentry/nextjs'
import currentHarvestData from '@/utils/harvestData.preval'

export const MemberAccountContext = createContext()

export function useMemberAccountContext() {
  return useContext(MemberAccountContext)
}

export function MemberAccountContextProvider({ children }) {
  const customerContext = useCustomerContext()

  const [reloadingData, setReloadingData] = useState(false);
  const [subsData, setSubsData] = useState(null);
  const [customerCredit, setCustomerCredit] = useState(undefined)
  const [availableAddons, setAvailableAddons] = useState([])
  const [availableSwapProducts, setAvailableSwapProducts] = useState([])

  async function populateAddonProducts() {
    const addons = await getAvailableAddons()
    setAvailableAddons(addons.filter(a =>
      userCanAccessContent({ contentTags: a.tags, userTags: customerContext.customer?.tags })
    ))
  }

  async function populateSwapProducts() {
    const swaps = await getAvailableSwaps()
    setAvailableSwapProducts(swaps.filter(s =>
      userCanAccessContent({ contentTags: s.tags, userTags: customerContext.customer?.tags })
    ))
  }

  const fetchCustomerData = useCallback(async (isReload = true) => {
    if (customerContext.customer?.id) {
      if (isReload) {
        setReloadingData(true);
      }

      const id = getIdFromSourceEntryId(customerContext.customer.id)
      fetch(`/api/account/${id}/get-subs`, {
        method: 'GET',
        headers: {
          'x-account-api-key': process.env.NEXT_PUBLIC_ACCOUNT_ENDPOINT_API_KEY
        }
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.message === 'success') {
            setSubsData(res.data)
          }
          if (isReload) {
            setReloadingData(false);
          }
        })
    } else {
      setSubsData(null)
    }
  }, [customerContext.customer]);

  // TODO: consolidate this into the fetch function above
  const fetchSubsData = useCallback(async (callback) => {
    if (customerContext.customer?.id) {
      const id = getIdFromSourceEntryId(customerContext.customer.id)
      await fetch(`/api/account/${id}/get-subs`, {
        method: 'GET',
        headers: {
          'x-account-api-key': process.env.NEXT_PUBLIC_ACCOUNT_ENDPOINT_API_KEY
        }
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.message === 'success') {
            setSubsData(res.data)
          }
        })
    } else {
      setSubsData(null)
    }
    if (!!callback) {
      callback();
    }
  }, [customerContext.customer]);

  const fetchCustomerCredit = useCallback(async () => {
    if (customerContext.customer?.id && customerContext.customerHasOneOfTags(['Prepaid', 'KingSustainer', 'SockeyeSustainer'])) {
      const id = getIdFromSourceEntryId(customerContext.customer.id)
      const result = await fetch(`/api/account/${id}/get-customer-credit`, {
        method: 'GET',
        headers: {
          'x-account-api-key': process.env.NEXT_PUBLIC_ACCOUNT_ENDPOINT_API_KEY
        }
      })
      if (result.ok) {
        const { data } = await result.json()
        setCustomerCredit(data)
      } else {
        setCustomerCredit(null)
        if (result.status === 500) {
          console.error(`failed to fetch customer credit - ${result.status} response`)
          return // this error is logged by the server
        } else {
          captureException(new Error(`failed to fetch customer credit - ${result.status} response`))
        }
      }
    } else {
      setCustomerCredit(null)
    }
  }, [customerContext.customer])

  useDeepCompareEffectNoCheck(() => {
    fetchCustomerData()
    fetchCustomerCredit()
  }, [customerContext.customer?.id])

  useDeepCompareEffectNoCheck(() => {
    populateAddonProducts()
    populateSwapProducts()
  }, [subsData])

  const subsDataWithHarvests = useMemo(
    () => subsData?.map(sub => {
      function getHarvestForLineItem(li) {
        let harvest
        if (!!li.harvestHandle) {
          const harvestType = li.isCuratedProduct ? 'harvest' : 'product'
          harvest = currentHarvestData.find(h => h.handle === li.harvestHandle && h.type === harvestType)

          if (harvest && li.isCuratedProduct) {
            // filter out older months
            var sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)
            const months = []
            harvest.fields.months.forEach((month) => {
              if (month.fishArray.length > 0 && new Date(month.sellStart) > sixMonthsAgo) {
                months.push({ ...month, month: month.month.trim().toLowerCase() })
              }
            })
            harvest.fields.months = months
          }
          if (harvest && !li.isCuratedProduct) {
            harvest.fields.months.forEach(m => {
              m.fishArray = m.harvest
            })
          }
        }
        return harvest
      }

      sub.subscription_lineitems.forEach(li => {
        li.harvest = getHarvestForLineItem(li)
      })

      sub.subscription_addons.forEach(li => {
        li.harvest = getHarvestForLineItem(li)
      })

      sub.subscription_swaps.forEach(li => {
        li.harvest = getHarvestForLineItem(li)
      })

      sub.subscription_open_orders.forEach(order => {
        order.subscription_lineitems.forEach(li => {
          li.harvest = getHarvestForLineItem(li)
        })

        order.subscription_addons.forEach(li => {
          li.harvest = getHarvestForLineItem(li)
        })

        order.subscription_swaps.forEach(li => {
          li.harvest = getHarvestForLineItem(li)
        })
      })

      return sub
    }),
    [subsData]
  )

  return (
    <MemberAccountContext.Provider value={{
      subsData: subsDataWithHarvests,
      customerCredit,
      reloadingData,
      fetchCustomerData,
      fetchSubsData,
      availableAddons,
      availableSwapProducts
    }}>
      {children}
    </MemberAccountContext.Provider>
  )
}
