import { forwardRef } from 'react'
import parse from 'html-react-parser'
import { useHeaderContext } from '@/context/HeaderContext'
import Link from 'next/link'

import classes from './PrimaryAnnouncement.module.scss'
import IconClose from '@/svgs/close.svg'

const PrimaryAnnouncement = forwardRef(({ props }, ref) => {
  const { showAnnoucementBar, removeAnnoucementBar } = useHeaderContext()

  if (!showAnnoucementBar) {
    return ''
  }

  const announcementText = props.announcementText
    .replace('<u>', '<Link><a href="' + props.announcementUrl + '"></Link>')
    .replace('</u>', '</a>')

  return (
    <div ref={ref} className={classes.primaryAnnouncement}>
      <button onClick={() => removeAnnoucementBar()}>
        <IconClose />
      </button>
      <div className={classes.primaryAnnouncementCopy}>
        {parse(announcementText)}
      </div>
    </div>
  )
})

export default PrimaryAnnouncement
