import { useState, useEffect, useRef } from 'react'
import { useModalContext } from '@/context/ModalContext'
import { useSearchModalContext } from '@/context/SearchModalContext'
import { useCustomerContext } from '@/context/CustomerContext'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { CSSTransition } from 'react-transition-group'
import Link from 'next/link'
import IconSearch from '@/svgs/search.svg'
import IconUser from '@/svgs/user.svg'
import { useRouter } from 'next/router'
import { useMemberAccountContext } from '@/context/MemberAccountContext'
import FreeShippingProgress from '@/components/FreeShippingProgress/FreeShippingProgress'
import ShippingInfoModal from '@/components/FreeShippingInfoModal/FreeShippingInfoModal'

const ProductPrimaryNavigation = ({ props, classes }) => {
  const router = useRouter()
  const modalContext = useModalContext()
  const { customer, logout } = useCustomerContext()
  const { openCartFlyout } = useHeadlessCheckoutContext()
  const { data } = useHeadlessCheckoutContext()
  const { customerCredit } = useMemberAccountContext()
  const [ searchTerm, setSearchTerm] = useState('');

  const [showUserMenuOptions, setShowUserMenuOptions] = useState(false)
  const [customerServiceMenuHovered, setShowCustomerServiceInfo] = useState(false)
  const [userMenuHovered, setUserMenuHovered] = useState(false)
  const [freeShippingProgressModalOpen, setFreeShippingProgressModalOpen] =
    useState(false)
  const cartMenuRef = useRef(null)
  const userMenuRef = useRef(null)

  const cartTotal = parseFloat(data?.cart?.cost?.subtotalAmount?.amount || 0)
  const shippingThreshold = 100

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    router.push(`/pages/search?query=${searchTerm}`); // Navigate to the search page with the query
  };

  const openAccountModal = (e) => {
    if (customer) {
      router.push('/account/subscriptions')
    } else {
      e.preventDefault()
      modalContext.setIsOpen(false)
      modalContext.setModalType('login')
      modalContext.setIsOpen(true)
    }
  }

  useEffect(() => {
    setShowCustomerServiceInfo(customerServiceMenuHovered)
  }, [customerServiceMenuHovered])

  useEffect(() => {
    setShowUserMenuOptions(userMenuHovered)
  }, [userMenuHovered])

  return (
    <ul className={[classes.navItems, classes.navUtilities].join(' ')}>
      <li className={classes['searchInputWrapper']}>
        <form onSubmit={handleSearchSubmit}>
          <div className={classes['searchInput']}>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="submit" className={classes['searchIconButton']}> <IconSearch className={classes['searchIcon']} /> </button> {/* Wrap Icon in button */}
          </div>
        </form>
      </li>
      <li
        className={[
          classes.navItem,
          classes.navItemAccount,
          classes['sm-desktop'],
        ].join(' ')}
        onMouseEnter={() => setUserMenuHovered(true)}
        onMouseLeave={() => setUserMenuHovered(false)}
      >
        <>
          <button
            className={classes.navIconButton}
            onClick={(e) => openAccountModal(e)}
          >
            <IconUser />
          </button>
          {customer && (
            <CSSTransition
              in={showUserMenuOptions}
              timeout={125}
              nodeRef={userMenuRef}
              unmountOnExit
              classNames={{
                enter: classes.accountMenuEnter,
                enterActive: classes.accountMenuEnterActive,
                enterDone: classes.accountMenuEnterDone,
                exit: classes.accountMenuExit,
              }}
            >
              <div ref={userMenuRef} className={classes.accountMenuModal}>
                <div className={classes.accountMenuOverlay}></div>
                <div
                  onMouseEnter={() => setUserMenuHovered(true)}
                  onMouseLeave={() => setUserMenuHovered(false)}
                  className={classes.accountMenu}
                >
                  <div className={classes.accountMenuContent}>
                    <h4
                      data-testid="customer-display-name"
                      className={classes.customerDisplayName}
                    >{`${customer.displayName}`}</h4>
                    <p>{`${customer.email}`}</p>
                    {customerCredit && (
                      <p
                        data-testid="customer-credit-display"
                        className={classes.customerCreditDisplay}
                      >{`Customer Credit: $${customerCredit.amount}`}</p>
                    )}
                    <ul>
                      <li>
                        <Link href={'/account/subscriptions'}>
                          Subscriptions
                        </Link>
                      </li>
                      <li>
                        <Link href={'/account/account-details'}>
                          Account Details
                        </Link>
                      </li>
                      <li>
                        <Link href={'/account/order-history'}>
                          Order History
                        </Link>
                      </li>
                      <li>
                        <Link href={'/account/referrals'}>Referrals</Link>
                      </li>
                      <li>
                        <a onClick={logout}>Sign Out</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </>
      </li>

      <li
        onClick={() => {
          openCartFlyout()
          if (router.pathname === '/cart' || router.pathname === '/checkout') {
            return
          }
        }}
        className={[classes.navItem, classes.cartItem].join(' ')}
        onMouseEnter={() => setFreeShippingProgressModalOpen(true)}
        onMouseLeave={() => setFreeShippingProgressModalOpen(false)}
      >
        <>
          <FreeShippingProgress
            amount={cartTotal}
            shippingThreshold={shippingThreshold}
          />
          <CSSTransition
            in={freeShippingProgressModalOpen}
            timeout={125}
            nodeRef={cartMenuRef}
            unmountOnExit
            classNames={{
              enter: classes.accountMenuEnter,
              enterActive: classes.accountMenuEnterActive,
              enterDone: classes.accountMenuEnterDone,
              exit: classes.accountMenuExit,
            }}
          >
            <ShippingInfoModal
              cartTotal={cartTotal}
              shippingThreshold={shippingThreshold}
              classes={classes}
              setFreeShippingProgressModalOpen={
                setFreeShippingProgressModalOpen
              }
              cartMenuRef={cartMenuRef}
            />
          </CSSTransition>
        </>
      </li>
    </ul>
  )
}

export default ProductPrimaryNavigation
