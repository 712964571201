export const formatPrice = (price, showTrailingZeroes = false) => {
  if ((price / 100) % 1 === 0 && !showTrailingZeroes) {
    return (price / 100).toFixed(0)
  }
  return (price / 100).toFixed(2)
}

export const formatShopifyPrice = (
  displayPrice,
  showTrailingZeroes = false,
) => {
  const price = parseFloat(displayPrice)
  if (price % 1 === 0 && !showTrailingZeroes) {
    return price.toFixed(0)
  }
  return price.toFixed(2)
}

export const getDiscountTotal = ({ discountValue, orderTotal }) => {
  if (discountValue.amount) return discountValue.amount
  if (discountValue.percentage) {
    return (parseFloat(orderTotal) * discountValue.percentage) / 100
  }
}

// NOTE: this only handles subscribe and save that is based on percentage off the total
/**
 * Determines the subscription price for the given variant. Selling plan option that will be used
 * will be the monthly plan, if available. Otherwise, the first plan on the variant will be used.
 * Only currently supports percentage based savings on subscriptions.
 * @returns number price of the variant
 */
export const getVariantSubscribedPrice = ({ variant, quantity = 1 }) => {
  const defaultSellingPlan =
    variant.sellingPlans.find((sp) => sp.name.toLowerCase() === 'monthly') ||
    variant.sellingPlans[0]
  let price = variant.price * quantity
  if (defaultSellingPlan && defaultSellingPlan.priceAdjustments?.length > 0) {
    defaultSellingPlan.priceAdjustments.forEach((adj) => {
      if (adj.adjustmentValue?.adjustmentPercentage) {
        price = (price * (100 - adj.adjustmentValue.adjustmentPercentage)) / 100
      }
    })
  }
  return formatShopifyPrice(price)
}

export const getPremiumSubscriptionPrice = (product) => {
  const price = product.metafields.find((f) => f.key === 'ps_price')?.value
  if (price) return parseInt(price)
  else return undefined
}

export const getPremiumSubscriptionWithShellfishPrice = (product) => {
  const price = product.metafields.find((f) => f.key === 'psws_price')?.value
  if (price) return parseInt(price)
  else return undefined
}

export const getSeafoodSubscriptionPrice = (product) => {
  const price = product.metafields.find((f) => f.key === 'sf_price')?.value
  if (price) return parseInt(price)
  else return undefined
}

export const getSalmonSubscriptionPrice = (product) => {
  const price = product.metafields.find((f) => f.key === 's_price')?.value
  if (price) return parseInt(price)
  else return undefined
}
