import { DateTime } from 'luxon'
import { RecipeJsonLd } from 'next-seo'
import parse from 'parse-duration'

const RecipeSD = ({ data }) => {
  if (!data.fields.seo || !data.fields.structuredData) {
    return ''
  }

  let { activeTime, cookTime, totalTime } = { ...data.fields.hero }
  const {
    metaTitle = '',
    metaDesc = '',
    shareGraphic = undefined,
  } = { ...data.fields.seo }
  const content = data.fields.content
  const { author = '' } = { ...data.fields.sidebar }
  const {
    calories = '',
    keywords = '',
    category = '',
    cuisine = '',
  } = { ...data.fields.structuredData }
  const images = []
  const ingredients = []

  // format time durations
  if (activeTime) {
    if (activeTime.includes('½')) activeTime = activeTime.replaceAll('½', '1/2')
    activeTime = `PT${Math.round(parse(activeTime, 'm'))}M`
  }
  if (cookTime) {
    if (cookTime.includes('½')) cookTime = cookTime.replaceAll('½', '1/2')
    cookTime = `PT${Math.round(parse(cookTime, 'm'))}M`
  }
  if (totalTime) {
    if (totalTime.includes('½')) totalTime = totalTime.replaceAll('½', '1/2')
    totalTime = `PT${Math.round(parse(totalTime, 'm'))}M`
  }

  if (shareGraphic) {
    images.push(shareGraphic.asset.url)
  }

  if (content) {
    content.forEach((item) => {
      if (item._type === 'image') {
        const image = item.asset.url
        images.push(image)
      }

      if (item._type === 'block' && item.listItem === 'bullet') {
        // Decision was made to only use bullets for ingredients - this allows us to have subheadings within the ingredients section
        let ingredient = ''
        item.children.forEach((ch) => (ingredient += ch.text))
        ingredients.push(ingredient)
      }
    })
  } else {
    return ''
  }

  let currentContentSectionHeading = ''
  let currentContentSectionSubheading = ''

  // TODO: this could be used for the ingredients portion of things as well if it was not focused on text and also pulled in the type/listItem properties of the elements
  const contentObj = content.reduce((carry, item) => {
    if (item.style == 'h1') {
      currentContentSectionHeading = item.children[0].text.toLowerCase()
      carry[currentContentSectionHeading] = []
      currentContentSectionSubheading = ''
    } else if (item.style == 'h2') {
      currentContentSectionSubheading = item.children[0].text
      carry[currentContentSectionHeading][currentContentSectionSubheading] = []
    } else if (item.children) {
      const text = item.children.map((child) => child.text).join(' ')
      if (text !== '') {
        if (currentContentSectionSubheading !== '') {
          carry[currentContentSectionHeading][currentContentSectionSubheading] =
            [
              ...carry[currentContentSectionHeading][
                currentContentSectionSubheading
              ],
              text,
            ]
        } else if (carry[currentContentSectionHeading]) {
          carry[currentContentSectionHeading] = [
            ...carry[currentContentSectionHeading],
            text,
          ]
        }
      }
    }
    return carry
  }, {})

  const directions = Object.keys(contentObj.directions)
    .map((key) => {
      const value = contentObj['directions'][key]
      return {
        name: key,
        text: Array.isArray(value) > 1 ? value.join('') : value,
      }
    })
    .filter(
      (item) =>
        item.text &&
        typeof item.text === 'string' &&
        item.text.trim().length > 0,
    )
    .map((item, index) => {
      return {
        ...item,
        name: `${index + 1}`,
      }
    })

  let datePublished = DateTime.fromISO(data.createdAt).toFormat('MM/dd/yyyy')

  if (data.fields?.publishedDate) {
    datePublished = DateTime.fromISO(data.fields.publishedDate).toFormat(
      'MM/dd/yyyy',
    )
  }

  // TODO: cook, prep, and total times are not formatted how google wants them
  // TODO: calories requires that the yield/servings also be added
  // TODO: dates are not in proper format
  // https://developers.google.com/search/docs/appearance/structured-data/recipe#guidelines
  return (
    <RecipeJsonLd
      name={metaTitle}
      description={metaDesc}
      datePublished={datePublished}
      authorName={[author.name]}
      prepTime={activeTime}
      cookTime={cookTime}
      totalTime={totalTime}
      keywords={keywords}
      category={category}
      cuisine={cuisine}
      calories={calories}
      images={images}
      instructions={directions}
      ingredients={ingredients}
    />
  )
}

export default RecipeSD
