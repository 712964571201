import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { useHeadlessCheckoutContext } from './HeadlessCheckoutContext'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/localStorageHelper'
import { getProductByHandle } from '@/utils/getProducts'
import { captureMessage } from '@sentry/nextjs'
import { getIdFromSourceEntryId } from '@/utils/sourceEntryId'

const PurchaseFlowContext = createContext()

export function usePurchaseFlowContext() {
  return useContext(PurchaseFlowContext)
}

const defaultOptions = {
  step: 1,
  products: [],
  productHandles: [],
  membership_type: 'subscription', // default to subscription, values can either be subscription, one-time-purchase, or prepaid
  frequency: 'monthly', // default to monthly, can also be every other month
  swapProducts: [],
  isBuildYourOwnBox: false,
}

export function PurchaseFlowProvider({ children }) {
  const { addItemsToOrder } = useHeadlessCheckoutContext()
  const [options, setOptions] = useState(defaultOptions)

  const selectedVariants = useMemo(
    () =>
      options.products?.map((p) => ({
        ...p.selectedVariant,
        quantity: p.quantity,
      })),
    [options?.products],
  )

  const getPurchaseFlowData = () => {
    const purchaseFlowData = getLocalStorageItem('purchase_flow_data')
    return purchaseFlowData ? JSON.parse(purchaseFlowData) : { ...options }
  }

  // step 1 - selecting product
  const selectSubscriptionBox = (product) => {
    let selectedVariant = product.selectedVariant || product.variants[0]
    setOptions({
      ...options,
      products: [{ ...product, selectedVariant, quantity: 1 }],
      productHandles: [
        {
          handle: product.content.handle,
          selectedVariant: selectedVariant.id,
          quantity: 1,
        },
      ],
      swapProducts: [],
      step: 2,
      membership_type: 'subscription',
    })
  }

  const updateBabProduct = (product, quantity) => {
    let products = options.products || []
    products = products.filter(
      (p) =>
        !p.tags.includes('Subscription Box') &&
        p.sourceEntryId !== product.sourceEntryId,
    ) // Remove any products that are subscription boxes or duplicates of the one being added

    if (quantity > 0) {
      products.push({ ...product, quantity })
    }

    setOptions({
      ...options,
      products,
      productHandles: products.map((p) => ({
        handle: p.handle,
        quantity: p.quantity,
      })),
      swapProducts: [],
      membership_type: 'subscription',
    })
  }

  const continueToPayment = async () => {
    const frequency = options.frequency
    const membershipType = options.membership_type
    const isPrepaid = membershipType === 'prepaid'
    const isOneTimePurchase =
      membershipType === 'one-time-purchase' || !membershipType

    const newCartItems = options.products.map((product) => ({
      variant: product.selectedVariant || product.variants[0],
      quantity: product.quantity,
      properties: {
        interval_text: isOneTimePurchase ? undefined : frequency,
        membership_type: membershipType,
      },
      product,
    }))

    if (isPrepaid) {
      const selectedVariant =
        options.products[0].selectedVariant || options.products[0].variants[0] // Only curated boxes will have prepaid right now, so we can assume there is only one variant to pick up on
      const prepaid = await getProductByHandle('prepaid-subscription')
      const prepaidVariant = prepaid.variants.find(
        (v) =>
          v.sku.replace('PREPAID-', '') === selectedVariant.sku.split('-')[0],
      )

      if (prepaidVariant) {
        newCartItems.push({
          variant: {
            ...prepaidVariant,
            selectedOptions: selectedVariant.content.selectedOptions,
          },
          product: prepaid,
          properties: {
            interval_text: frequency,
            membership_type: membershipType,
          },
          quantity: frequency.toLowerCase() === 'every other month' ? 5 : 11,
        })
      } else {
        captureMessage(
          `Unable to find matching prepaid variant for variant ${getIdFromSourceEntryId(selectedVariant.sourceEntryId)}`,
        )
      }
    }

    options.swapProducts?.forEach((swp) => {
      newCartItems.push({
        variant: swp.variants[0],
        product: swp,
      })
    })

    await addItemsToOrder({ items: newCartItems, redirectToCart: true })
  }

  const selectSwapProducts = (swapProducts) => {
    setOptions({
      ...options,
      swapProducts,
    })
  }

  const loadDataFromStorage = async () => {
    // TODO: only make this happen if there is no data loaded in context already
    let localStoragePurchaseFlowData = getPurchaseFlowData()
    if (Object.keys(localStoragePurchaseFlowData).length) {
      if (
        localStoragePurchaseFlowData.productHandles &&
        localStoragePurchaseFlowData.productHandles.length > 0
      ) {
        const products = await Promise.all(
          localStoragePurchaseFlowData.productHandles.map(
            async ({ handle, quantity, selectedVariant }) => {
              const product = await getProductByHandle(handle)
              return {
                ...product,
                quantity,
                selectedVariant:
                  product?.variants.find((v) => v.id === selectedVariant) ||
                  product?.variants.at(0),
              }
            },
          ),
        )
        localStoragePurchaseFlowData.products = products
      }
    }

    setOptions({ ...localStoragePurchaseFlowData })
  }

  useEffect(() => {
    const saveData = { ...options }
    // delete saveData.products // TODO: quantity is currently being stored on the product. Should figure out how to keep that value. So is the selected variant. We need to keep that as well.
    // delete saveData.swapProducts
    setLocalStorageItem('purchase_flow_data', JSON.stringify(saveData))
  }, [options])

  return (
    <PurchaseFlowContext.Provider
      value={{
        options,
        selectedVariants,
        swapProducts: options?.swapProducts || [],
        setOptions,
        selectSwapProducts,
        selectSubscriptionBox,
        continueToPayment,
        updateBabProduct,
        loadDataFromStorage,
      }}
    >
      {children}
    </PurchaseFlowContext.Provider>
  )
}
