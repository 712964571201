import { DateTime } from 'luxon'
import { ArticleJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

const BlogSD = ({ data }) => {
  const router = useRouter()

  if (!data.fields.seo) {
    return ''
  }

  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`
  const {
    metaTitle = '',
    metaDesc = '',
    shareGraphic = undefined,
  } = data.fields.seo
  let datePublished = DateTime.fromISO(data.createdAt).toFormat('MM/dd/yyyy')
  const dateModified = DateTime.fromISO(data.updatedAt).toFormat('MM/dd/yyyy')

  if (data.fields.publishedDate) {
    datePublished = DateTime.fromISO(data.fields.publishedDate).toFormat(
      'MM/dd/yyyy',
    )
  }

  const images = []

  if (shareGraphic) {
    images.push(shareGraphic.asset.url)
  }

  // TODO: dates are not in the proper format
  // TODO: could add author and publisher information
  return (
    <ArticleJsonLd
      type="Blog"
      url={url}
      title={metaTitle}
      images={images}
      datePublished={datePublished}
      dateModified={dateModified}
      description={metaDesc}
    />
  )
}

export default BlogSD
