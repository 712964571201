import useProductsReviews from '@/hooks/useProductsReviews'
import { stripHtml } from '@/utils/htmlUtils'
import { ProductJsonLd } from 'next-seo'
import { useRouter } from 'next/router'

const ProductSD = ({ data }) => {
  const router = useRouter()
  const reviews = useProductsReviews([data?.handle])

  const product = data.content
  const { variants } = data
  const { title, description } = product
  const url = `${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`
  const images = product.media.map((image) => image.src)

  const totalReviews = reviews.length
  const averageRating =
    reviews.reduce((total, review) => total + review.rating, 0) / totalReviews

  const aggregateRating =
    totalReviews > 0
      ? {
          bestRating: '5',
          worstRating: '0',
          ratingCount: totalReviews,
          ratingValue: averageRating,
        }
      : undefined

  return (
    <ProductJsonLd
      productName={title}
      images={images}
      description={description ? stripHtml(description) : null}
      brand="Sitka Salmon Shares"
      offers={variants.map((variant) => {
        return {
          price: variant.price ? variant.price.toFixed(2) : '',
          priceCurrency: 'USD',
          availability: variant.availableForSale ? 'InStock' : 'OutOfStock',
          url,
        }
      })}
      aggregateRating={aggregateRating}
      reviews={reviews?.map((review) => ({
        author: review.author,
        reviewBody: review.body,
        reviewRating: {
          bestRating: '5',
          ratingValue: `${review.rating}`,
          worstRating: '0',
        },
      }))}
    />
  )
}

export default ProductSD
