import { createClient } from "@sanity/client";

const sanityClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2023-05-03',
  useCdn: true
});

export function getSanityClient(previewToken) {
  if (previewToken) console.debug('loading data in preview mode')
  return previewToken
    ? sanityClient.withConfig({
      token: previewToken,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts'
    })
    : sanityClient;
}

export default sanityClient