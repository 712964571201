import { ArticleProvider } from '@/context/ArticleContext'
import { ArticleFiltersDrawerProvider } from '@/context/ArticleFiltersDrawerContext'
import { TheCatchProvider } from '@/context/TheCatchContext'
import { ModalProvider } from '@/context/ModalContext'
import { PDPDrawerProvider } from '@/context/PDPDrawerContext'
import { CustomerProvider } from '@/context/CustomerContext'
import { PurchaseFlowProvider } from '@/context/PurchaseFlowContext'
import { HeadlessCheckoutProvider } from '@/context/HeadlessCheckoutContext'
import { HeaderProvider } from '@/context/HeaderContext'
import { KnowYourFishDrawerProvider } from '@/context/KnowYourFishDrawerContext'
import { SearchProvider } from '@/context/SearchModalContext'
import { EditScheduleDrawerProvider } from '@/context/EditScheduleDrawerContext'
import { EditShippingAddressDrawerProvider } from '@/context/EditShippingAddressDrawerContext'
import { SubscriptionProductDrawerProvider } from '@/context/SubscriptionProductDrawerContext'
import { MarketingProvider } from '@/context/MarketingContext'
import { useRouter } from 'next/router'

import StructuredData from '../SEO/StructuredData'
import { MemberAccountContextProvider } from '@/context/MemberAccountContext'
import dynamic from 'next/dynamic'
import { BabDrawerContextProvider } from '@/context/BabDrawerContext'
import { SpeedInsights } from '@vercel/speed-insights/next'

const DynamicProductFooter = dynamic(() => import('@/components/Layout/ProductFooter'))

function Layout({ children, headerSettings, footerSettings, searchLinks }) {
  const router = useRouter();

  // Check for product type (this will be undefined for non-product pages)
  const productType = children?.props?.defaultProduct?.productType;

  // Determine which header/footer to use based on route and product type
  const isProductPage = router.pathname.startsWith('/products/');
  const isFreezerProduct = isProductPage && ['Freezer Box', 'Freezer Sale'].includes(productType);

  const FooterComponent = DynamicProductFooter 

  return (
    <CustomerProvider>
      <ModalProvider>
        <MarketingProvider>
          <MemberAccountContextProvider>
            <HeadlessCheckoutProvider pageHandle={children.props.handle}>
              <PurchaseFlowProvider>
                <PDPDrawerProvider>
                  <BabDrawerContextProvider>
                    <KnowYourFishDrawerProvider>
                      <SubscriptionProductDrawerProvider>
                        <EditScheduleDrawerProvider>
                          <EditShippingAddressDrawerProvider>
                            <ArticleFiltersDrawerProvider>
                              <ArticleProvider>
                                <TheCatchProvider>
                                  <SearchProvider searchLinks={searchLinks}>
                                    <HeaderProvider
                                      content={headerSettings}
                                      pageHandle={children.props.handle}
                                      isFreezerProduct={isFreezerProduct}
                                    >
                                      <StructuredData type="breadcrumb" />
                                      {children}
                                      <SpeedInsights />
                                      <FooterComponent content={footerSettings} />
                                    </HeaderProvider>
                                  </SearchProvider>
                                </TheCatchProvider>
                              </ArticleProvider>
                            </ArticleFiltersDrawerProvider>
                          </EditShippingAddressDrawerProvider>
                        </EditScheduleDrawerProvider>
                      </SubscriptionProductDrawerProvider>
                    </KnowYourFishDrawerProvider>
                  </BabDrawerContextProvider>
                </PDPDrawerProvider>
              </PurchaseFlowProvider>
            </HeadlessCheckoutProvider>
          </MemberAccountContextProvider>
        </MarketingProvider>
      </ModalProvider>
    </CustomerProvider>
  )
}

export default Layout
