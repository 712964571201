import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import IconCart from '@/svgs/cart.svg';
import styles from './FreeShippingProgress.module.scss';

const getPathColor = (percentage) => {
  if (percentage < 100) {
    return '#163144';
  }
  return 'green';
};

const FreeShippingProgress = ({ amount, shippingThreshold }) => {
  const percentage = Math.min((amount / shippingThreshold) * 100, 100);

  return (
    <div className={styles.container}>
      <div style={{ width: 400 }}>
        <CircularProgressbar
          value={percentage}
          styles={buildStyles({
            pathColor: getPathColor(percentage),
            trailColor: '#e0e0e0',
            circleRatio: 0.75,
          })}
          className={styles.progressIcon}
        />
      </div>
      <div className={styles.iconContainer}>
        <IconCart className={styles.icon} />
      </div>
    </div>
  );
};

export default FreeShippingProgress;

