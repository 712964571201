//#region GROQ QUERIES
export const CONTENT_SECTIONS_GROQ = `
  ...,
  _type == "genericSection" => {
    backgroundImage {
      ...,
      image {
        ...,
        asset->{...}
      }
    },
    ...,
    content[]{
      ...,
      _type == "genericContainer" => {
        ...,
        components[]{
          ...,
          _type == "genericImage" => {
            ...,
            image {
              ...,
              asset->{...}
            }
          }
        }
      }
    }
  },
  _type == "faqs" => {
    faqItems[]->{
      ...,
      answer[]{
        ...,
        iconWithTextBlock{
          ...,
        },
        icon->{
          ...,
          image{
            ...,
            asset->{...}
          },
        },
        valuePropsBlock{
          ...,
          image->{
            ...,
            asset->{...}
          },
        },
        valueProp2{
          ...,
          image->{
            ...,
            asset->{...}
          },
        },
        valueProp3{
          ...,
          image->{
            ...,
            asset->{...}
          },
        },
        valueProp4{
          ...,
          image->{
            ...,
            asset->{...}
          },
        },
      },
    }
  },
  image{
    ...,
    crop,
    hotspot,
    asset->{...}
  },
  footerImage{
    ...,
    crop,
    hotspot,
    asset->{...}
  },
  decorativeImage {
    ...,
    image{
      ...,
      asset->{...}
    },
  },
  subscriptionList[]{
    ...,
    backgroundImage{
      ...,
      crop,
      hotspot,
      asset->{...}
    }
  },
  fullBleedHero{
    ...,
    desktopBackgroundImage{
      ...,
      crop,
      hotspot,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      crop,
      hotspot,
      asset->{...}
    },
  },
  desktopBackgroundImage{
    ...,
    crop,
    hotspot,
    asset->{...}
  },
  mobileBackgroundImage{
    ...,
    crop,
    hotspot,
    asset->{...}
  },
  image{
    ...,
    asset->{...}
  },
  desktopImage{
    ...,
    asset->{...}
  },
  mobileImage{
    ...,
    asset->{...}
  },
  imageTwo{
    ...,
    asset->{...}
  },
  imageThree{
    ...,
    asset->{...}
  },
  illustration{
    ...,
    asset->{...}
  },
  illustration2{
    ...,
    asset->{...}
  },
  images[]{
    ...,
    crop,
    hotspot,
    asset->{...}
  },
  blog->{
    ...,
    seo{
      ...,
      shareGraphic{
        ...,
        asset->{...}
      }
    },
    content[]{
      ...,
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      }
    },
    hero{
      ...,
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      }
    },
  },
  harvestList[]->{
    ...,
    months[]->{
      ...,
      fishArray[]{
        ...,
        species->{
          ...,
          image {
            ...,
            asset->{...}
          },
        },
        locations->{
          ...,
          image{
            ...,
            asset->{...}
          },
        },
        fishermen[]->{
          ...,
          image{
            ...,
            asset->{...}
          },
          flyoutImage{
            ...,
            asset->{...}
          },
          locations->{
            ...,
            image{
              ...,
              asset->{...}
            }
          },
          species[]->{
            ...,
            image{
              ...,
              asset->{...}
            },
          },
        },
        culinary->{
          ...,
          image{
            ...,
            asset->{...}
          },
        },
      }
    }
  },
  _type == "valueProps" => {
    valueProps[]{
      ...,
      propImage{
        ...,
        asset->{...}
      }
    }
  },
  _type == "threeImageBlock" => { // Agrega el bloque para "threeImageBlock"
            ...,
            header,
            title,
            alt,
            topPadding,
            bottomPadding,
            image {
              ...,
              asset->{...}
            },
            imageDescriptionOne,
            altTwo,
            imageTwo {
              ...,
              asset->{...}
            },
            imageDescriptionTwo,
            altThree,
            imageThree {
              ...,
              asset->{...}
            },
            imageDescriptionThree
          },
  _type == "halfHeroHalfSlider" => {
    articles[]->{
      ...,
      seo{
        ...,
        shareGraphic{
          ...,
          asset->{...}
        }
      },
      hero{
        ...,
        desktopBackgroundImage{
          ...,
          asset->{...}
        },
        mobileBackgroundImage{
          ...,
          asset->{...}
        }
      },
      blog->{
        ...,
        seo{
          ...,
          shareGraphic{
            ...,
            asset->{...}
          }
        },
        content[]{
          ...,
          desktopBackgroundImage{
            ...,
            asset->{...}
          },
          mobileBackgroundImage{
            ...,
            asset->{...}
          }
        },
        hero{
          ...,
          desktopBackgroundImage{
            ...,
            asset->{...}
          },
          mobileBackgroundImage{
            ...,
            asset->{...}
          }
        },
      },
      sidebar{
        ...,
        author->{
          ...,
          image{
            ...,
            asset->{...}
          }
        },
        knowYourFishList[]{
          ...,
        }
      }
    }
  },
  categoriesList[]{
    ...,
    desktopImage{
      ...,
      asset->{...}
    },
    mobileImage{
      ...,
      asset->{...}
    },
  },
  splitHero[]{
    ...,
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    },
  },
  pressLogos[]{
    ...,
    logo{
      ...,
      asset->{...}
    }
  },
  recipeArticle->{
    ...,
    hero{
      ...,
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      }
    },
    blog->{
      ...,
      content[]{
        ...,
        image{
          ...,
          asset->{...}
        },
        footerImage{
          ...,
          asset->{...}
        },
        desktopBackgroundImage{
          ...,
          asset->{...}
        },
        mobileBackgroundImage{
          ...,
          asset->{...}
        },
        illustration{
          ...,
          asset->{...}
        },
        illustration2{
          ...,
          asset->{...}
        }
      },
      filterGroups[]->{
        ...
      },
      hero{
        ...,
        desktopBackgroundImage{
          ...,
          asset->{...}
        },
        mobileBackgroundImage{
          ...,
          asset->{...}
        }
      }
    },
    sidebar{
      ...,
      author->{
        ...,
        image{
          ...,
          asset->{...}
        }
      },
      knowYourFishList{
        ...,
        knowYourFishes[]->{
          ...,
          image{
            ...,
            asset->{...}
          },
          description->{
            ...,
          },
          content[]{
            ...,
            image{
              ...,
              asset->{...}
            },
            footerImage{
              ...,
              asset->{...}
            },
            illustration{
              ...,
              asset->{...}
            },
            illustration2{
              ...,
              asset->{...}
            },
            illustrationAlt{
              ...,
              asset->{...}
            },
            illustration2Alt{
              ...,
              asset->{...}
            },
          }
        }
      }
    }
  },
  categoriesList[]{
    ...,
    image{
      ...,
      asset->{...}
    },
  },
  _type == "reviewsCarousel" => {
    products[]->{
      ...
    },
  }
`
//#endregion
