import React from 'react';
import FreeShippingProgress from '../FreeShippingProgress/FreeShippingProgress';

const ShippingInfoModal = ({
  cartTotal,
  shippingThreshold,
  classes,
  setFreeShippingProgressModalOpen,
  cartMenuRef,
}) => {

  let freeShippingText = <>SPEND $100 AND GET <br />FREE SHIPPING</>;

  if (cartTotal >= shippingThreshold) {
    freeShippingText = <>YOU EARNED <br />FREE SHIPPING</>;
  } else if (cartTotal > 0) {
    freeShippingText = <>${(shippingThreshold - cartTotal).toFixed(2)} AWAY FROM <br />FREE SHIPPING</>;
  }

  return (
    <div ref={cartMenuRef} className={classes.customerShippingInfoModal}>
      <div
        onMouseEnter={() => setFreeShippingProgressModalOpen(true)}
        onMouseLeave={() => setFreeShippingProgressModalOpen(false)}
        className={classes.customerShippingInfo}
      >
        <div className={classes.customerShippingInfoContent}>
          <FreeShippingProgress
            amount={cartTotal}
            shippingThreshold={shippingThreshold}
          />
          <h5 className={classes.freeShippingText}>
            {freeShippingText}
          </h5>
        </div>
      </div>
    </div>
  )
};

export default ShippingInfoModal;
