import { queryShopifyStorefront } from "../services/shopifyStorefront"
import { buildSourceEntryId, getIdFromSourceEntryId } from "./sourceEntryId"

export async function getSellingPlanGroupsByProductId(productId) {
  // make sure this product id is formatted as a source entry id
  const id = buildSourceEntryId({ id: getIdFromSourceEntryId(productId), objectType: 'Product' })
  const result = await queryShopifyStorefront({ query: GET_PRODUCT_SELLING_PLANS_BY_ID, variables: { id } })
  if (!result || !result.product) return []
  const sellingPlans = result.product.sellingPlanGroups.nodes.map(g => g.sellingPlans.nodes).flat(1)
  return sellingPlans
}

//#region QUERIES
export const STOREFRONT_SELLING_PLAN_SCHEMA = `
            id
            name
            description
            options {
              name
              value
            }
            recurringDeliveries
            checkoutCharge {
              type
              value {
                ... on MoneyV2 {
                  __typename
                  amount
                }
                ... on SellingPlanCheckoutChargePercentageValue {
                  __typename
                  percentage
                }
              }
            }
            priceAdjustments {
              adjustmentValue {
                ... on SellingPlanFixedAmountPriceAdjustment {
                  __typename
                  adjustmentAmount {
                    amount
                  }
                }
                ... on SellingPlanFixedPriceAdjustment {
                  __typename
                  price {
                    amount
                  }
                }
                ... on SellingPlanPercentagePriceAdjustment {
                  __typename
                  adjustmentPercentage
                }
              }
              orderCount
            }
`

const GET_PRODUCT_SELLING_PLANS_BY_ID = `
query GetProductSellingPlans($id: ID!) {
  product(id: $id) {
    sellingPlanGroups(first: 10) {
      nodes {
        sellingPlans(first: 10) {
          nodes {
            ${STOREFRONT_SELLING_PLAN_SCHEMA}
          }
        }
      }
    }
  }
}
`
//#endregion