import sanityClient from '../services/sanityClient'

export const getBoxDetailByHandles = async (handles) => {
  const QUERY = (handles) => `
    *[_type == 'boxDetails' && handle.current in [${handles.map((el) => `"${el}"`).join(',')}]]
    {
      ...,
      "objectID": _id,
      stamps{
        ...,
        mobileImage{
          ...,
          asset->{...}
        },
        desktopImage{
          ...,
          asset->{...}
        },
      },
      faqs{
        ...,
        faqItems[]->{
          ...
        }
      },
      membershipInfo{
        ...,
        faqItems[]->{
          ...
        }
      },
      badges{
        badgeItems[]{
          asset->{url}
        }
      }
    }
  `

  if (!handles.length) {
    return []
  }
  const boxes = await sanityClient.fetch(QUERY(handles))
  const boxDetails = boxes.map((boxDetail) => {
    return {
      createdAt: boxDetail._createdAt,
      fields: {
        __typename: boxDetail._type,
        ...boxDetail,
      },
      handle: boxDetail.handle.current,
      published: true,
      sourceEntryId: '',
      sourceId: boxDetail.objectID,
      tags: [],
      title: boxDetail.title,
      type: boxDetail._type,
      updatedAt: boxDetail._updatedAt,
    }
  })
  return boxDetails
}
