import Link from 'next/link'
import ResponsiveImage from '@/components/ResponsiveImage'
import classes from './ModalProductCard.module.scss'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import { getVariantSubscribedPrice } from '@/utils/formatPrice'

function ModalProductCard({ product }) {
  let productLink = `/products/${encodeURIComponent(product.handle)}`
  let price = product.price

  const isCuratedSubscription = product.tags.includes('Subscription Box')

  if (isCuratedSubscription) {
    productLink = `/pages/choose-your-plan?expand=${product.handle}`
    if (product.variants?.length > 0)
      price = getVariantSubscribedPrice({ variant: product?.variants[0] })
  }

  const articleCardImgLoader = ({ src }) => {
    return `${src}?w=690`
  }

  return (
    product && (
      <div className={classes['card']}>
        <div className={classes['card__inner']}>
          <div className={classes['card__image']}>
            <Link href={productLink} legacyBehavior>
              <a className={classes['media']}>
                <ResponsiveComponent
                  desktopBreakpoint={'xxs'}
                  mobile={
                    product.product_image && (
                      <ResponsiveImage
                        loader={articleCardImgLoader}
                        src={product.product_image}
                        alt={product.title}
                        className={classes.image}
                      />
                    )
                  }
                  desktop={
                    product.product_image && (
                      <ResponsiveImage
                        loader={articleCardImgLoader}
                        src={product.product_image}
                        alt={product.title}
                        className={classes.image}
                      />
                    )
                  }
                />
              </a>
            </Link>
          </div>

          <div className={classes['card__content']}>
            {product.title && (
              <h4
                className={`${classes['title']} heading--product-title uppercase`}
              >
                {product.title}
              </h4>
            )}

            <div className={classes['price-wrap']}>
              <div className={classes['price']}>
                <p className="secondary--body">${price}</p>
              </div>
              {product.weight && (
                <p className={`${classes['weight']} secondary--body`}>
                  {product.weight}
                </p>
              )}
            </div>

            <Link href={productLink} legacyBehavior>
              <a className="btn salmon">View Details</a>
            </Link>
          </div>
        </div>
      </div>
    )
  )
}

export default ModalProductCard
