import classes from './MainNavigation.module.scss'
import Link from 'next/link'
import PrimaryNavigation from './PrimaryNavigation'
import NavigationUtilities from './NavigationUtilities'
import Image from 'next/image'

const MainNavigation = ({ props }) => {
  return (
    <div className={`${classes.mainNavigation} ${classes.mainNavContainer}`}>
      <PrimaryNavigation props={props} classes={classes} />
      <div className={classes.navLogo}>
        <div className={classes.navLogoContainer}>
          <Link href="/">
            <Image
              src={props.logo.asset.url}
              fill
              alt={props.logo.asset.alt || 'Logo'}
              priority
            />
          </Link>
        </div>
      </div>
      <NavigationUtilities props={props} classes={classes} />
    </div>
  )
}

export default MainNavigation
