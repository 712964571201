import { useEffect } from 'react'

const CustomZendeskWidget = () => {
  useEffect(() => {
    const isMobile = () => window.innerWidth <= 768 // 768px is the breakpoint for mobile

    const checkIframe = setInterval(() => {
      // get the iframe element of the Zendesk widget
      const iframe = document.getElementById('launcher')
      if (iframe) {
        if (isMobile()) {
          iframe.style.left = '0px'
          iframe.style.right = 'unset'
          iframe.style.display = 'none'
        } else {
          iframe.style.right = '0px'
          iframe.style.left = 'unset'
        }

        // Clear the interval once the iframe is found
        clearInterval(checkIframe)
      }
    }, 1000)

    return () => clearInterval(checkIframe)
  }, [])

  // There's no need to render anything in the DOM
  return null
}

export default CustomZendeskWidget
