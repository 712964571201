import { createContext, useContext, useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useCustomerContext } from './CustomerContext'
import { useRouter } from 'next/router'
import {
  dataLayerATC,
  dataLayerRFC,
  dataLayerViewCart,
  dataLayerEvent,
} from '@/utils/dataLayer'
import { useMemberAccountContext } from './MemberAccountContext'
import {
  refreshCart,
  initCart,
  postShipOptions,
  addLineItems,
  updateLineItems,
  deleteLineItems,
  updateOrderAttributes,
  associateCustomerWithOrder,
  updateDiscountCodes,
  associateAddressWithOrder,
  selectDeliveryOptions,
} from '@/utils/cart'
import { DateTime } from 'luxon'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/localStorageHelper'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import PQueue from 'p-queue'
import { captureException } from '@sentry/nextjs'
import { getIdFromSourceEntryId } from '@/utils/sourceEntryId'
import { formatCheckoutUrl } from '@/utils/checkoutUrl'

const cartUpdateQueue = new PQueue({ concurrency: 1 })
cartUpdateQueue.on('active', () => {
  console.debug(
    `Working on item.  Size: ${cartUpdateQueue.size}  Pending: ${cartUpdateQueue.pending}`,
  )
})
cartUpdateQueue.on('error', (error) => {
  console.error(error)
  captureException(error)
})
cartUpdateQueue.on('add', () => {
  console.debug(
    `Task is added.  Size: ${cartUpdateQueue.size}  Pending: ${cartUpdateQueue.pending}`,
  )
})
cartUpdateQueue.on('next', () => {
  console.debug(
    `Task is completed.  Size: ${cartUpdateQueue.size}  Pending: ${cartUpdateQueue.pending}`,
  )
})

export const HeadlessCheckoutContext = createContext()

const DynamicCheckoutFlyout = dynamic(
  () => import('@/components/HeadlessCheckout/CheckoutFlyout'),
)

export function useHeadlessCheckoutContext() {
  return useContext(HeadlessCheckoutContext)
}

export function HeadlessCheckoutProvider({ children }) {
  const router = useRouter()
  const [data, setData] = useState(undefined) // Should only be used outside of the headless cart context OR to trigger useEffect hooks. This will not always be the most up to date. Local storage value will be the most up to date value.
  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [checkoutIsReady, setCheckoutIsReady] = useState(false)
  const { customer, getCustomerAccessToken } = useCustomerContext()
  const { subsData } = useMemberAccountContext()

  // Loading state for the line item update when the shipping line is expedited
  const [updatingLineItemWithExpeditedShipping, setUpdatingLineItemWithExpeditedShipping] = useState(false)

  const requiresShipping = data?.cart?.lineItems?.some(
    (li) => li.variant.requiresShipping,
  )
  const totalQuantity = data?.cart?.lineItems?.reduce(
    (quantity, li) => (quantity += li.quantity),
    0,
  )

  function openFlyout() {
    setFlyoutOpen(true)
  }

  function openFlyoutModal() {
    setFlyoutOpen(true)
  }

  function closeFlyout() {
    setFlyoutOpen(false)
  }

  //#region LOCAL STORAGE

  function getLocalStorageCartData() {
    const cartData = getLocalStorageItem('cart_data')
    return cartData ? JSON.parse(cartData) : undefined
  }

  function getCartId() {
    return getLocalStorageCartData()?.cartId
  }

  function saveDataInLocalStorage({ cart, userErrors = [] }) {
    if (cart) {
      const lineItems = (cart?.lines.nodes || []).map((li) => ({
        ...li,
        title: li.merchandise.product.title,
        variant: li.merchandise,
        properties: li.attributes.reduce(
          (props, { key, value }) => ((props[key] = value), props),
          {},
        ),
        discounts: li.discountAllocations || [],
      }))

      const discounts = cart?.discountAllocations || []

      const shippingAddress = cart?.deliveryGroups.nodes[0]?.deliveryAddress

      const shippingLines = cart?.deliveryGroups.nodes
        .map((group) =>
          group?.deliveryOptions.map((opt) => ({ ...opt, groupId: group.id })),
        )
        .flat(1)
        .reduce((groupedLines, line) => {
          const cost = parseFloat(line.estimatedCost.amount)
          const selectedDeliveryOptionInput = {
            deliveryGroupId: line.groupId,
            deliveryOptionHandle: line.handle,
          }
          const existing = groupedLines.find((l) => l.title === line.title)
          if (existing) {
            existing.selectedDeliveryOptionInput.push(
              selectedDeliveryOptionInput,
            )
            if (existing.cost < cost) existing.cost = cost
          } else {
            groupedLines.push({
              selectedDeliveryOptionInput: [selectedDeliveryOptionInput],
              title: line.title,
              cost,
            })
          }
          return groupedLines
        }, [])

      cart = {
        ...cart,
        properties: cart?.attributes.reduce(
          (props, { key, value }) => ((props[key] = value), props),
          {},
        ),
        lineItems,
        discounts,
        shippingAddress,
        shippingLines,
      }
    } else {
      // Don't erase a cart because of errors
      cart = getLocalStorageCartData()?.cart
      resetGiftOrderMetaData()
    }

    const cartData = {
      cart,
      cartId: cart?.id,
      checkoutUrl: formatCheckoutUrl(cart?.checkoutUrl),
      userErrors,
    }
    setLocalStorageItem('cart_data', JSON.stringify(cartData))
    setData({ ...data, ...cartData })
  }

  //#endregion

  //#region CREATE CHECKOUT

  async function initializeCart(newCart = {}) {
    setIsLoading(true)
    try {
      // Do not add any function calls here that add anything to the cart update queue. That will break things.
      const initData = await initCart({ newCart })
      saveDataInLocalStorage(initData)
    } finally {
      setIsLoading(false)
    }
  }

  async function resumeCart({ cartId }) {
    setIsLoading(true)
    try {
      console.debug('resumeCartId: ', cartId)
      if (!cartId) {
        return await initializeCart()
      }
      const cartData = await refreshCart(cartId)
      if (!cartData) {
        // cart unable to be resumed
        return await initializeCart()
      }
      saveDataInLocalStorage(cartData)
      await setMarketingData()
    } finally {
      setIsLoading(false)
    }
  }

  async function reloadCart() {
    await resumeCart({ cartId: getCartId() })
  }

  useDeepCompareEffectNoCheck(() => {
    // a null cart means the cart has been completed and needs to be reinitialized
    if (data && !data.cart?.id) {
      initializeCart()
    } else if (data && data.cart.id) {
      if (customer)
        addCustomerToOrder({
          customerAccessToken: getCustomerAccessToken(),
          email: customer.email,
        })
      setMarketingData()
    }
  }, [data?.cart?.id])

  useEffect(() => {
    if (data?.cart?.lineItems.length > 0) {
      setCheckoutIsReady(true)
    }
  }, [data?.cart?.lineItems.length])

  //#endregion

  //#region LINE ITEM

  function isAssociatedWithSubscription(item) {
    return (
      (!!item.properties?.membership_type &&
        item.properties?.membership_type !== 'earlybird' &&
        item.properties?.membership_type !== 'one-time-purchase') ||
      item.variant?.product?.handle === 'sitka-seafood-intro-box' ||
      item.properties?.product_handle === 'sitka-seafood-intro-box'
    )
  }

  function mapSubscriptionItemToOrder(item) {
    const { variant, quantity = 1, properties = {} } = item

    const sellingPlans = variant.sellingPlans
    const applicableSellingPlan = sellingPlans.find(
      (sp) =>
        sp.options
          .find((opt) => opt.name === 'Delivery frequency')
          ?.value.toLowerCase()
          .trim() === properties.interval_text?.toLowerCase().trim(), // matching frequency
    )

    if (!applicableSellingPlan)
      captureException(
        `Subscription Product (or Variant) ${getIdFromSourceEntryId(variant.sourceEntryId)} did not have applicable selling plan for ${JSON.stringify(properties)}`,
      )
    delete properties.interval_text

    const newItem = {
      merchandiseId: variant.sourceEntryId,
      quantity: quantity,
      attributes: Object.keys(properties).map((key) => ({
        key,
        value: properties[key],
      })),
    }

    if (applicableSellingPlan) newItem.sellingPlanId = applicableSellingPlan.id

    return newItem
  }

  function mapNonSubscriptionItemToOrder(item) {
    const { variant, quantity = 1, properties = {} } = item
    delete properties.interval_text

    return {
      merchandiseId: variant.sourceEntryId,
      quantity: quantity,
      attributes: Object.keys(properties).map((key) => ({
        key,
        value: properties[key],
      })),
    }
  }

  function getTags(lineItem) {
    return (
      lineItem?.tags ||
      lineItem?.variant?.tags ||
      lineItem?.product?.tags ||
      lineItem?.variant?.product?.tags
    )
  }

  async function addItemsToOrder({
    items,
    openFlyout = false,
    redirectToCart = false,
  }) {
    async function performUpdate() {
      setIsLoading(true)
      try {
        let cart = getLocalStorageCartData()?.cart
        if (!cart) {
          return
        }

        const curatedSub = items.find((li) =>
          getTags(li).includes('Subscription Box'),
        )
        const containsCuratedSubscription = !!curatedSub
        const curatedSubSku = curatedSub?.variant.sku
        if (containsCuratedSubscription)
          items = items.map((i) => ({
            ...i,
            properties: {
              ...i.properties,
              sub: curatedSubSku,
            },
          }))

        let subscriptionProducts = items.filter((i) =>
          isAssociatedWithSubscription(i),
        )
        let nonSubscriptionProducts = items.filter(
          (i) => !isAssociatedWithSubscription(i),
        )
        const newItems = []

        newItems.push(...subscriptionProducts.map(mapSubscriptionItemToOrder))
        newItems.push(
          ...nonSubscriptionProducts.map(mapNonSubscriptionItemToOrder),
        )

        const cartData = await addLineItems({
          cartId: getCartId(),
          lineItems: [...newItems],
        })

        saveDataInLocalStorage(cartData)
        items.forEach((item) =>
          dataLayerATC({ item: { ...item, quantity: item.quantity || 1 } }),
        )
      } finally {
        setIsLoading(false)
      }
    }

    await cartUpdateQueue.add(performUpdate)
    if (openFlyout) openFlyoutModal()
    if (redirectToCart && router.pathname !== '/cart') router.push('/cart')
  }

  async function addItemToOrder({
    variant,
    product,
    quantity = 1,
    properties = {},
    openFlyout = false,
    redirectToCart = false,
  }) {
    await addItemsToOrder({
      items: [{ variant, product, quantity, properties }],
      openFlyout,
      redirectToCart,
    })
  }

  // get expedited shipping options based on cart shippingAddress
  async function getExpeditedShippingOption() {
    const cart = getLocalStorageCartData()?.cart
    const shippingAddress = cart?.shippingAddress
    const shippingOptions = await getShipOptions(shippingAddress?.zip)

    const expeditedShippingOption = shippingOptions.expedited

    if (!expeditedShippingOption) {
      return null
    }

    // map the expedited shipping option
    const mappedExpeditedShippingOption = {
      ...expeditedShippingOption,
      estimatedDeliveryDateDisplay: DateTime.fromISO(expeditedShippingOption.estimatedDeliveryDate).toFormat('EEEE, MMM d'),
      display: <>{`Delivered as soon as ${expeditedShippingOption.estimatedDeliveryDateDisplay}`}<sup>{`\u2020`}</sup></>,
      title: 'Expedited Shipping',
      price: expeditedShippingOption?.cost,
    }

    return mappedExpeditedShippingOption
  }

  async function updateLineItem(lineItemToUpdate) {
    async function performUpdate() {
      const cart = getLocalStorageCartData()?.cart

      // Verify if the shipping line is expedited before the update
      const isExpeditedBeforeUpdate = cart?.deliveryGroups?.nodes[0]?.selectedDeliveryOption?.code === "Expedited Shipping"

      if (isExpeditedBeforeUpdate) {
        setUpdatingLineItemWithExpeditedShipping(true)
      }

      const associatedLineItems = cart?.lineItems.filter(
        (li) =>
          li.properties.sub === lineItemToUpdate.properties.sub &&
          li.variant?.id !== lineItemToUpdate.variant?.id,
      )
      const curatedSub = cart?.lineItems.find(
        (li) =>
          getTags(li).includes('Subscription Box') &&
          li.properties.sub === lineItemToUpdate.properties.sub,
      )
      const curatedFrequency =
        curatedSub?.sellingPlanAllocation?.sellingPlan?.name.toLowerCase()

      const lineItemsToUpdate = [lineItemToUpdate].concat(
        associatedLineItems.map((li) => {
          const isSwap = li.variant?.sku.includes('SWP-')
          const isPrepaid = li.variant?.sku.includes('PREPAID-')

          const isMonthly = curatedFrequency === 'monthly'
          let quantity = li.quantity || 0
          if (isSwap) quantity = lineItemToUpdate.quantity
          if (isPrepaid)
            quantity = lineItemToUpdate.quantity * (isMonthly ? 11 : 5)
          return {
            ...li,
            quantity,
          }
        }),
      )

      const updatedData = await updateLineItems({
        cartId: getCartId(),
        lineItems: lineItemsToUpdate,
      })


      saveDataInLocalStorage(updatedData)

      if (isExpeditedBeforeUpdate) {
        const expeditedShippingOption = await getExpeditedShippingOption()

        if (expeditedShippingOption) {
          // We directly update the shipping line here because we don't want to call a queue.add inside of a queue.add
          const currentShippingLines = getLocalStorageCartData()?.cart.shippingLines
          const selectedLine = currentShippingLines.find(
            (line) => line.title?.toLowerCase()?.includes(expeditedShippingOption.title.toLowerCase())
          )

          if (selectedLine) {
            const reselectedData = await selectDeliveryOptions({
              cartId: getCartId(),
              options: selectedLine.selectedDeliveryOptionInput,
            })

            // Update the order metadata
            await updateExpeditedOrderMetaData([
              { key: 'selected_shipping_line', value: expeditedShippingOption.title || '' },
              { key: 'ship_week_preference', value: expeditedShippingOption.shipWeek || '' },
              { key: 'ship_week_display', value: expeditedShippingOption.estimatedDeliveryDateDisplay || '' },
            ])

            saveDataInLocalStorage(reselectedData)

            if (selectedLine.title) dataLayerEvent({ event: 'set_shipping_line' })
          }
        }
      }

      setUpdatingLineItemWithExpeditedShipping(false)
    }
    await cartUpdateQueue.add(performUpdate)
  }

  async function removeLineItem(lineItemToRemove) {
    async function performUpdate() {
      const cart = getLocalStorageCartData()?.cart

      const removedItemIsSwap = lineItemToRemove.variant?.sku.includes('SWP-')
      const removedItemIsCuratedSub =
        getTags(lineItemToRemove).includes('Subscription Box')
      const lineItemsToRemove = [lineItemToRemove]
      const remainingLineItems = cart?.lineItems.filter(
        (li) => li.variant?.id !== lineItemToRemove.variant?.id,
      )
      if (removedItemIsSwap && remainingLineItems.length) {
        // Remove all other swaps if swap is being removed (don't want people to be able to get stuck with just one swap)
        const additionalSwapItems = remainingLineItems.filter((li) =>
          li.variant?.sku.includes('SWP-'),
        )
        lineItemsToRemove.push(...additionalSwapItems)
      } else if (removedItemIsCuratedSub) {
        const additionalSubRelatedItems = remainingLineItems.filter(
          (li) => li.properties?.sub === lineItemToRemove.properties?.sub,
        )
        lineItemsToRemove.push(...additionalSubRelatedItems)
      } else if (
        // Remove all remaining line items if the only ones left are swaps
        remainingLineItems.every(
          (li) =>
            li.variant?.sku.includes('SWP-') ||
            li.variant?.sku.includes('PREPAID-'),
        )
      ) {
        lineItemsToRemove.push(...remainingLineItems)
      }
      lineItemsToRemove.forEach((item) => dataLayerRFC({ item }))

      const updatedData = await deleteLineItems({
        cartId: getCartId(),
        lineItems: lineItemsToRemove,
      })

      // delete cart data in local storage there are no items in order
      if (updatedData?.cart?.lines.nodes.length === 0) {
        await initializeCart()
      } else {
        saveDataInLocalStorage(updatedData)
      }
    }
    await cartUpdateQueue.add(performUpdate)
  }

  //#endregion

  //#region DISCOUNTS AND GIFT CARDS

  async function applySingleDiscount(discount) {
    async function performUpdate() {
      if (!discount) return
      const currentDiscounts = [
        ...(getLocalStorageCartData()?.cart.discountCodes || []),
      ]
      const alreadyExists = currentDiscounts.find(
        (dc) => dc.code === discount && dc.applicable,
      )
      if (!alreadyExists) {
        const updatedData = await updateDiscountCodes({
          cartId: getCartId(),
          discountCodes: [...currentDiscounts.map((dc) => dc.code), discount],
        })
        saveDataInLocalStorage(updatedData) // it seems like they rate limit the discount mutation much more easily than the other mutations -- may get null data returned
      }
    }
    await cartUpdateQueue.add(performUpdate)
  }

  async function removeDiscount(discount) {
    async function performUpdate() {
      const currentDiscounts = [
        ...(getLocalStorageCartData()?.cart.discountCodes.map(
          (dc) => dc.code,
        ) || []),
      ]
      const discountExists = currentDiscounts.find(
        (code) => code === discount.code || code === discount,
      )
      if (discountExists) {
        const discountCodes = currentDiscounts.filter(
          (d) => d !== discount.code && d !== discount,
        )
        const updatedData = await updateDiscountCodes({
          cartId: getCartId(),
          discountCodes,
        })
        saveDataInLocalStorage(updatedData)
      }
    }
    await cartUpdateQueue.add(performUpdate)
  }

  function getEligibleDiscounts({ lineItems = [] }) {
    var discounts = []

    if (!lineItems || lineItems.length < 1) {
      resetGiftOrderMetaData()
      return discounts
    }

    const hasSub = lineItems.some((item) => {
      return getTags(item).includes('Subscription Box')
    })
    const hasFb = lineItems.some((item) => {
      return getTags(item).includes('freezer')
    })

    const PPEB_SIX_MONTHS = 6
    const PPEB_TWELVE_MONTHS = 12

    const hasPPEB6 = lineItems.some((item) => {
      return (
        item?.variant?.sku.includes('PPEB') &&
        item?.quantity === PPEB_SIX_MONTHS
      )
    })
    const hasPPEB12 = lineItems.some((item) => {
      return (
        item?.variant?.sku.includes('PPEB') &&
        item?.quantity === PPEB_TWELVE_MONTHS
      )
    })

    var customerTags = ''
    if (customer && customer.tags != '') {
      customerTags = customer.tags
    }

    // DETERMINE MEMBERSHIP TIER
    var membership = 'guest'
    if (customerTags.includes('Employee')) {
      membership = 'Employee'
    } else if (customerTags.includes('KingSustainer')) {
      membership = 'KingSustainer'
    } else if (customerTags.includes('SockeyeSustainer')) {
      membership = 'SockeyeSustainer'
    } else if (customerTags.includes('Prepaid')) {
      membership = 'PrepaidMember'
    } else if (customerTags.includes('PremiumMember')) {
      membership = 'PremiumMember'
    } else if (customerTags.includes('Member')) {
      membership = 'Member'
    }

    // console.debug('discount attributes: sub:' + hasSub + ', fb:' + hasFb + ', ppeb:' + (hasPPEB6 || hasPPEB12) + ', membership:' + membership + ', tags:', customerTags)

    // AUTO DISCOUNTS FOR OTB
    if (hasFb && membership === 'Employee') {
      discounts.push({
        name: '30% Employee Discount',
        getDiscountedPrice: (price) => {
          return price * 0.7
        },
      })
    } else if (hasFb && membership === 'KingSustainer') {
      discounts.push({
        name: '20% King Sustainer Discount',
        getDiscountedPrice: (price) => {
          return price * 0.8
        },
      })
    } else if (hasFb && membership === 'SockeyeSustainer') {
      discounts.push({
        name: '15% Sustainer Discount',
        getDiscountedPrice: (price) => {
          return price * 0.85
        },
      })
    } else if (hasFb && membership === 'PrepaidMember') {
      discounts.push({
        name: '15% Member Discount',
        getDiscountedPrice: (price) => {
          return price * 0.85
        },
      })
    } else if (
      hasFb &&
      (membership === 'PremiumMember' || membership === 'Member')
    ) {
      discounts.push({
        name: '10% Member Discount',
        getDiscountedPrice: (price) => {
          return price * 0.9
        },
      })
    }

    // AUTO DISCOUNTS FOR SUBSCRIPTIONS
    if (hasSub && membership === 'KingSustainer') {
      discounts.push({
        name: '10% King Sustainer Discount',
        getDiscountedPrice: (price) => {
          return price * 0.9
        },
      })
    } else if (hasSub && membership === 'SockeyeSustainer') {
      discounts.push({
        name: '5% Sustainer Discount',
        getDiscountedPrice: (price) => {
          return price * 0.95
        },
      })
    }

    // AUTO DISCOUNTS FOR PPEB 2023
    // king sustainers get to double down with additional 10%, sockeye sustainers get to double down with additional 5%
    // 6 month ppeb's get 5% off, 12 month ppeb's get 11% off
    if (hasPPEB6 && membership === 'KingSustainer') {
      discounts.push({
        name: 'PPDEarly Bird Sustainer 20%',
        getDiscountedPrice: (price) => {
          return price * 0.8
        },
      })
    } else if (hasPPEB12 && membership === 'KingSustainer') {
      discounts.push({
        name: 'PPDEarly Bird Sustainer 20%',
        getDiscountedPrice: (price) => {
          return price * 0.8
        },
      })
    } else if (hasPPEB6 && membership === 'SockeyeSustainer') {
      discounts.push({
        name: 'PPDEarly Bird Sustainer 15%',
        getDiscountedPrice: (price) => {
          return price * 0.85
        },
      })
    } else if (hasPPEB12 && membership === 'SockeyeSustainer') {
      discounts.push({
        name: 'PPDEarly Bird Sustainer 15%',
        getDiscountedPrice: (price) => {
          return price * 0.85
        },
      })
    }
    //  else if (hasPPEB6) {
    //   discounts.push({ name: 'PPDEarly Bird 5%', getDiscountedPrice: (price) => { return price * 0.95 } });
    // } else if (hasPPEB12) {
    //   discounts.push({ name: 'PPDEarly Bird 11%', getDiscountedPrice: (price) => { return price * 0.89 } });
    // }

    // Return sorted array so that best discount is at the beginning of the array
    discounts = discounts.sort((a, b) => {
      return a.getDiscountedPrice(100) - b.getDiscountedPrice(100)
    })

    return discounts
  }

  // update applicable discounts any time the customer and/or line items change
  // Apply any discounts that are applicable to the cart and not already present on the cart
  useDeepCompareEffectNoCheck(() => {
    if (getCartId()) {
      async function performUpdate() {
        const lineItems = getLocalStorageCartData()?.cart?.lineItems || []
        const discounts =
          getLocalStorageCartData()?.cart?.discountCodes.filter(
            (d) => d.applicable,
          ) || []
        const properties = getLocalStorageCartData()?.cart?.properties || {}
        const totalQuantity = lineItems.reduce(
          (total, li) => (total += li.quantity),
          0,
        )

        var eligibleDiscounts = getEligibleDiscounts({ lineItems })

        if (lineItems.length < 1) return

        const hasSub = lineItems.some((li) =>
          li?.variant.product.tags.includes('Subscription Box'),
        )
        const isPrepaid = lineItems.some((li) =>
          li?.variant.sku.includes('PREPAID-'),
        )
        const isGift = !!properties.recipient_email

        // AUTO DISCOUNT FOR REFERRALS
        if (navigator.cookieEnabled) {
          if (
            sessionStorage.getItem('utm_source') === 'member_referral' &&
            !customer
          ) {
            if (hasSub) {
              eligibleDiscounts.push({
                name: '$25 Refer a Friend',
                getDiscountedPrice: (price) => price - 25,
              })
            }
          }
        }

        // AUTO DISCOUNT FOR PREPAID SUBSCRIPTIONS
        if (hasSub && isPrepaid) {
          // Gift Subscription
          if (isGift && totalQuantity >= 12)
            eligibleDiscounts.push({
              name: 'Annual Prepaid Membership Gift',
              getDiscountedPrice: (price) => price * 0.95,
            })
          // Annual Prepaid Memberships
          else if (!isGift)
            eligibleDiscounts.push({
              name: 'Annual Prepaid Membership',
              getDiscountedPrice: (price) => price * 0.95,
            })
        }

        eligibleDiscounts = eligibleDiscounts.sort((a, b) => {
          return a.getDiscountedPrice(100) - b.getDiscountedPrice(100)
        })

        if (
          eligibleDiscounts.length > 0 &&
          !eligibleDiscounts.every((ed) => discounts.some((d) => d === ed.code))
        ) {
          const applicableDiscounts = [...eligibleDiscounts].reduce(
            (array, ed) => {
              const existing = array.find(
                (d) => (d.code || d.name) === (ed.code || ed.name),
              )
              if (!existing) array.push(ed)
              return array
            },
            [...discounts],
          )
          const updatedData = await updateDiscountCodes({
            cartId: getCartId(),
            discountCodes: applicableDiscounts.map((d) => d.code || d.name),
          })
          if (updatedData) saveDataInLocalStorage(updatedData)
          eligibleDiscounts.forEach((d) =>
            dataLayerEvent({ event: 'apply_discount_code' }),
          )
          console.debug('applied auto discounts', eligibleDiscounts)
        }
      }
      cartUpdateQueue.add(performUpdate)
    }
  }, [customer, totalQuantity, data?.cartId]) // Making this run on total quantity instead of the line items array will prevent this from rerunning when a user removes a discount from the cart.

  //#endregion

  //#region METADATA

  async function updateExpeditedOrderMetaData(additionalAttributes) {
    const cart = getLocalStorageCartData()?.cart

    if (cart) {
      let attributes = [...additionalAttributes]

      cart.attributes.forEach((att) => {
        const existing = attributes.find((a) => a.key === att.key)
        if (!existing) attributes.push(att)
      })

      attributes = attributes.filter(
        (att) =>
          !!att && att.value !== '' && typeof att.value !== 'undefined',
      )

      const updatedData = await updateOrderAttributes({
        cartId: cart.id,
        attributes,
      })

      saveDataInLocalStorage(updatedData)
    }
  }

  async function updateOrderMetaData(additionalAttributes) {
    // TODO: make this more intelligent and only update the attribute if the attributes have actually changed
    async function performUpdate() {
      const cart = getLocalStorageCartData()?.cart
      if (cart) {
        let attributes = [...additionalAttributes]
        cart.attributes.forEach((att) => {
          const existing = attributes.find((a) => a.key === att.key)
          if (!existing) attributes.push(att)
        })
        attributes = attributes.filter(
          (att) =>
            !!att && att.value !== '' && typeof att.value !== 'undefined',
        )
        const updatedData = await updateOrderAttributes({
          cartId: cart.id,
          attributes,
        })

        saveDataInLocalStorage(updatedData)
      }
    }

    await cartUpdateQueue.add(performUpdate, { priority: 1 }) // give these higher priority because other updates use this data to function correctly
  }

  async function updateGiftOptionMetadata({
    recipient_name,
    recipient_email,
    gift_message,
  }) {
    const attributes = [
      { key: 'recipient_name', value: recipient_name },
      { key: 'recipient_email', value: recipient_email },
      { key: 'gift_message', value: gift_message },
      {
        key: 'is_gift_order',
        value: 'true',
      },
    ]
    await updateOrderMetaData(attributes)
  }

  async function resetGiftOrderMetaData() {
    await updateOrderMetaData([
      { key: 'is_gift_order', value: 'false' },
      { key: 'recipient_name', value: '' },
      { key: 'recipient_email', value: '' },
      { key: 'gift_message', value: '' },
    ]);
  }

  async function setMarketingData() {
    const newOrderMetaData = []

    let gaClientId = sessionStorage.getItem('GAClientId')
    if (gaClientId) {
      newOrderMetaData.push({
        key: 'google-clientID',
        value: gaClientId,
      })
    }

    let attributions = {}

    if (navigator.cookieEnabled) {
      attributions.utm_source = sessionStorage.getItem('utm_source')
      attributions.utm_medium = sessionStorage.getItem('utm_medium')
      attributions.utm_campaign = sessionStorage.getItem('utm_campaign')
      attributions.utm_content = sessionStorage.getItem('utm_content')
      attributions.referrer = sessionStorage.getItem('referrer')
      attributions.landing_page = sessionStorage.getItem('landing_page')

      if (
        sessionStorage.getItem('utm_source') ||
        sessionStorage.getItem('utm_medium') ||
        sessionStorage.getItem('utm_campaign') ||
        sessionStorage.getItem('utm_content') ||
        sessionStorage.getItem('referrer') ||
        sessionStorage.getItem('landing_page')
      ) {
        newOrderMetaData.push({
          key: 'marketingAttributions',
          value: JSON.stringify(attributions),
        })
      }

      if (sessionStorage.getItem('utm_source') === 'member_referral') {
        newOrderMetaData.push({
          key: 'member_referral',
          value: sessionStorage.getItem('utm_content'),
        })
      }

      if (sessionStorage.getItem('irclickid')) {
        newOrderMetaData.push({
          key: 'affiliate_referral',
          value: sessionStorage.getItem('irclickid'),
        })
      }
    }

    await updateOrderMetaData(newOrderMetaData)
  }

  //#endregion

  //#region CUSTOMER

  async function addCustomerToOrder({ customerAccessToken, email }) {
    async function performUpdate() {
      const updatedData = await associateCustomerWithOrder({
        cartId: getCartId(),
        customerAccessToken,
        email,
      })
      saveDataInLocalStorage(updatedData)
      //TODO: does datalayer need update?
    }
    await cartUpdateQueue.add(performUpdate)
  }

  useDeepCompareEffectNoCheck(() => {
    if (data?.cart?.buyerIdentity?.email) {
      const isGuestCheckout =
        getLocalStorageCartData()?.cart?.properties?.is_guest_checkout ===
        'true'
      if (!!customer && isGuestCheckout)
        updateOrderMetaData([{ key: 'is_guest_checkout', value: 'false' }])
      if (!customer && !isGuestCheckout)
        updateOrderMetaData([{ key: 'is_guest_checkout', value: 'true' }])
    }
  }, [data?.cart?.buyerIdentity?.email])

  async function removeCustomerFromOrder() {
    async function performUpdate() {
      const updatedData = await associateCustomerWithOrder({
        cartId: getCartId(),
      })
      saveDataInLocalStorage(updatedData)
    }
    await cartUpdateQueue.add(performUpdate)
  }

  useDeepCompareEffectNoCheck(() => {
    if (!data?.cart) return

    // if logged in and order does not have customer, add customer to order
    if (
      customer?.email &&
      data?.cart?.buyerIdentity?.email !== customer.email
    ) {
      addCustomerToOrder({
        customerAccessToken: getCustomerAccessToken(),
        email: customer.email,
      })
      // if logged out and order has customer, remove customer from order
    } else if (!customer) {
      // if not guest cart - remove guest customer from order
      if (data?.cart?.properties?.is_guest_checkout === 'false') {
        removeCustomerFromOrder()
      }
    }
  }, [customer, data?.cart?.properties?.is_guest_checkout])

  //#endregion

  //#region SHIPPING ADDRESS AND LINES

  async function updateShippingAddress(shippingAddress) {
    async function performUpdate() {
      const updatedData = await associateAddressWithOrder({
        cartId: getCartId(),
        address: shippingAddress,
      })
      saveDataInLocalStorage(updatedData)
      dataLayerEvent({ event: 'set_shipping_address' })
    }
    await cartUpdateQueue.add(performUpdate)
  }

  async function selectShippingLine({
    shippingLine,
    shipWeek,
    estimatedDeliveryDateDisplay,
  }) {

    if (shippingLine) {
      async function performUpdate() {
        // We have to reformat/get the shipping groups from the current cart because they change
        const currentShippingLines =
          getLocalStorageCartData()?.cart.shippingLines
        const selectedLine = currentShippingLines.find(
          (line) => line.title === shippingLine.title,
        )

        if (selectedLine) {
          const updatedData = await selectDeliveryOptions({
            cartId: getCartId(),
            options: selectedLine.selectedDeliveryOptionInput,
          })
          saveDataInLocalStorage(updatedData)
        } else {
          console.warn(`Unable to set shipping line ${shippingLine.title}`)
        }
      }
      await cartUpdateQueue.add(performUpdate)
      if (shippingLine.title?.toLowerCase().includes('ship with next')) {
        await applySingleDiscount('Ship with Next Order')
      } else {
        await removeDiscount('Ship with Next Order')
      }
      if (shippingLine.title) dataLayerEvent({ event: 'set_shipping_line' })
    }

    await updateOrderMetaData([
      { key: 'selected_shipping_line', value: shippingLine?.title || '' },
      { key: 'ship_week_preference', value: !!shipWeek ? `${shipWeek}` : '' },
      { key: 'ship_week_display', value: estimatedDeliveryDateDisplay || '' },
    ])
  }

  async function getShipOptions(zip) {
    if (zip && zip.length === 5) {
      const cart = getLocalStorageCartData()?.cart
      let bundledShipWeek = undefined
      if (subsData && subsData.length > 0) {
        // Find the earliest date in the subscription data
        const earliestDate = Math.min(
          ...subsData
            .map((d) => DateTime.fromISO(d.fulfill_start))
            .filter((d) => d.isValid),
        )

        const nextShipDate = DateTime.fromMillis(earliestDate).setZone('UTC')
        bundledShipWeek = `${nextShipDate.weekNumber}`
      }

      return await postShipOptions(zip, bundledShipWeek, cart.lineItems)
    } else {
      return []
    }
  }

  //#endregion

  useEffect(() => {
    const handleRouteChange = async () => {
      if (!getLocalStorageCartData()) {
        await initializeCart()
      }
      closeFlyout()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    if (flyoutOpen) {
      document.querySelector('html').classList.add('disable-scroll')
      if (data) {
        dataLayerViewCart({ cart: data.cart })
      }
    }
    if (!flyoutOpen)
      document.querySelector('html').classList.remove('disable-scroll')
  }, [flyoutOpen])

  useEffect(() => {
    if (router.isReady) {
      const localCartId = getCartId()
      console.debug('load cart from query param ', router.query.public_order_id)
      console.debug('load cart from local storage ', localCartId)

      if (router.query.public_order_id || router.query.cartId) {
        resumeCart({
          cartId: router.query.public_order_id || router.query.cartId,
        })
      } else if (!!localCartId) {
        resumeCart({ cartId: localCartId })
      } else {
        initializeCart()
      }
    }
  }, [router.isReady])

  // Filters subscriptions with 'membership_type' of 'prepaid'
  const subscriptionItems = data?.cart?.lineItems?.filter(item =>
    item.attributes.some(attr => attr.key === 'membership_type')
  );
// Filters subscriptions that have a selling plan
  const subsWithSellingPlan = subscriptionItems?.filter(item => item?.sellingPlanAllocation != null);
// Checks if all subscriptions in the cart with selling plan are giftable
  const allSubsInCartAreGiftable = subscriptionItems?.length === 0 || subsWithSellingPlan?.every(item =>
    item.attributes.some(attr => attr.key === 'is_gift_sub_order' && attr.value === 'true')
  );

  return (
    <HeadlessCheckoutContext.Provider
      value={{
        data,
        initializeCart,
        reloadCart,
        updateLineItem,
        removeLineItem,
        cartFlyoutOpen: flyoutOpen,
        openCartFlyout: openFlyout,
        closeCartFlyout: closeFlyout,
        addItemToOrder,
        addItemsToOrder,
        updateOrderMetaData,
        addCustomerToOrder,
        removeCustomerFromOrder,
        isLoading,
        setIsLoading,
        getShipOptions,
        checkoutIsReady,
        setCheckoutIsReady,
        updateGiftOptionMetadata,
        getEligibleDiscounts,
        requiresShipping,
        applySingleDiscount,
        removeDiscount,
        selectShippingLine,
        setMarketingData,
        updateShippingAddress,
        updatingLineItemWithExpeditedShipping,
        allSubsInCartAreGiftable,
        resetGiftOrderMetaData,
      }}
    >
      <DynamicCheckoutFlyout />
      {children}
    </HeadlessCheckoutContext.Provider>
  )
}
