import { createContext, useContext, useReducer, useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { getProductByHandle } from '@/utils/getProducts'
import { getBoxDetailByHandles } from '@/utils/getBoxDetailByHandles'

const DynamicPDPDrawer = dynamic(() => import('@/components/Layout/PDPDrawer'))

const PDPDrawerContext = createContext()

//#region STATE
function boxReducer(state, action) {
  switch (action.type) {
    case 'open_drawer': {
      return {
        ...state,
        isOpen: true,
        activeProduct: action.payload,
      }
    }
    case 'close_drawer': {
      return {
        ...state,
        isOpen: false,
        activeProduct: null,
      }
    }
    case 'add_box_data': {
      return {
        ...state,
        boxManager: action.payload,
      }
    }
    default:
      return state
  }
}

const initialState = {
  isOpen: false,
  activeProduct: null,
  boxManager: {},
}
//#endregion

export function usePDPDrawerContext() {
  return useContext(PDPDrawerContext)
}

export function PDPDrawerProvider({ children }) {
  const router = useRouter()
  const [state, dispatch] = useReducer(boxReducer, initialState)
  const { isOpen, activeProduct, boxManager } = state
  const activeProductHandle = activeProduct?.product?.content.handle

  const updateParam = () => {
    if (
      router.pathname === '/pages/choose-your-plan' &&
      router.isReady &&
      (!activeProductHandle ||
        activeProductHandle === 'sitka-seafood-intro-box')
    ) {
      router.replace(
        {
          pathname: '/pages/choose-your-plan',
          query: activeProductHandle
            ? { expand: activeProductHandle }
            : undefined,
        },
        undefined,
        { shallow: true },
      )
    }
  }

  const closeDrawer = () => {
    dispatch({ type: 'close_drawer' })
  }

  // boxDetails is Sanity content that compliments Shopify product data linked by the productHandle
  // note to future self: PDP flyout drawer assumes it is only used by member purchase flow
  const openDrawer = async ({
    productData,
    customActionButtonText,
    customActionButtonHandler,
    isProductDetail = false,
  }) => {
    const productHandle = productData.content.handle
    let product = boxManager[productHandle]
    if (!product) {
      const boxDetails = await getBoxDetailByHandles([productHandle])
      product = {
        product: productData,
        boxDetails: boxDetails[0],
      }
      const updatedBoxManager = {
        ...boxManager,
        [productHandle]: product,
      }
      dispatch({ type: 'add_box_data', payload: updatedBoxManager })
    }

    dispatch({
      type: 'open_drawer',
      payload: {
        ...product,
        isProductDetail,
        customActionButtonHandler,
        customActionButtonText,
      },
    })
  }

  const openProductDetailDrawer = async ({
    productData,
    customActionButtonHandler,
    customActionButtonText,
  }) => {
    await openDrawer({
      productData,
      customActionButtonHandler,
      customActionButtonText,
      isProductDetail: true,
    })
  }

  //#region USE EFFECTS
  useEffect(() => {
    if (isOpen) document.querySelector('html').classList.add('disable-scroll')
    if (!isOpen)
      document.querySelector('html').classList.remove('disable-scroll')
    updateParam()
  }, [isOpen])

  // triggers pdp flyout on page load if url has a param of expand
  useEffect(() => {
    if (router.isReady && router.query?.expand) {
      getProductByHandle(router.query?.expand).then((product) => {
        if (product) openDrawer({ productData: product })
      })
    }
  }, [router.isReady])

  useEffect(() => {
    if (router.query.expand) {
      const box = boxManager[router.query.expand]
      if (!box) {
        getProductByHandle(router.query?.expand).then((product) => {
          if (product) openDrawer({ productData: product })
        })
      } else {
        openDrawer({ productData: box.product })
      }
    }
    router.beforePopState(({ as }) => {
      dispatch({ type: 'close_drawer' })
      return true
    })
    return () => {
      router.beforePopState(() => true)
    }
  }, [router])
  //#endregion

  return (
    <PDPDrawerContext.Provider
      value={{
        isOpen,
        openDrawer,
        closeDrawer,
        openProductDetailDrawer,
      }}
    >
      {isOpen && activeProduct && <DynamicPDPDrawer box={activeProduct} />}
      {children}
    </PDPDrawerContext.Provider>
  )
}
